body {
  font-family: 'Open Sans', sans-serif;
  color: #333;
  font-size: 15px;
  line-height: 21px;
  font-weight: 400;
  text-align: left;
}

h1 {
  margin-top: 0px;
  font-size: 38px;
  line-height: 46px;
  font-weight: 400;
  text-transform: none;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h3 {
  margin-top: 0px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Oswald, sans-serif;
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-bottom: 20px;
  font-weight: 300;
}

a {
  background-color: transparent;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: #aaa;
  text-decoration: none;
}

a:hover {
  color: #666;
  text-decoration: none;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-weight: 300;
}

label {
  font-weight: 400;
}

.nav {
  border-top: 1px solid hsla(0, 0%, 7%, .08);
  background-color: #fff;
  color: #fff;
}

.nav.top {
  background-color: #eee;
  text-align: center;
}

.nav-link {
  margin-top: 0px;
  padding: 22px 20px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: hsla(0, 0%, 40%, .6);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #666;
  text-decoration: none;
}

.nav-link.w--current {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #666;
}

.nav-link.nav-dropdown {
  z-index: 10;
  padding-right: 32px;
}

.nav-link.nav-dropdown.w--open {
  color: #666;
}

.nav-link.top {
  padding: 9px 10px;
  color: hsla(0, 0%, 40%, .6);
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: none;
}

.nav-link.top:hover {
  color: #666;
}

.nav-link.top.w--current {
  background-color: transparent;
  color: #666;
}

.nav-link.top.pull-right {
  display: inline-block;
}

.nav-link.top.pull-right.show-mobile {
  display: none;
}

.nav-link._2 {
  width: 50px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.nav-link.mid {
  display: inline-block;
  padding: 34px 10px;
  color: hsla(0, 0%, 40%, .6);
  text-transform: none;
}

.nav-link.mid:hover {
  color: #666;
}

.nav-link.mid.w--current {
  background-color: transparent;
  color: #666;
}

.nav-menu {
  display: block;
  float: none;
  text-align: center;
}

.nav-menu.top {
  display: block;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.input2 {
  position: relative;
  height: 40px;
  padding: 0px 14px;
  border: 1px none #666;
  border-radius: 0px;
  background-color: #fff;
}

.login-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 44px;
  height: 40px;
  padding: 0px;
  float: right;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  font-family: 'Corin design', sans-serif;
  color: #aaa;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}

.login-btn:hover {
  border: 1px none #666;
  background-color: #555;
  opacity: 1;
  color: #fff;
}

.slider {
  overflow: hidden;
  height: 70vh;
  max-height: 700px;
  max-width: 100%;
  min-height: 400px;
  background-color: transparent;
}

.section {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #fff;
}

.section.tint {
  background-color: #fff;
}

.section.tint.bespoke {
  padding-bottom: 50px;
  background-color: #fff;
  font-size: 19px;
  line-height: 27px;
}

.section.cta {
  position: relative;
  overflow: hidden;
  height: 66vh;
  max-height: 700px;
  min-height: 400px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  text-align: center;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .3);
}

.section.blog {
  padding-right: 20px;
  padding-left: 20px;
}

.section.social {
  display: none;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.section.testimonials {
  display: none;
  overflow: hidden;
  color: hsla(0, 0%, 100%, .7);
}

.section.grey-gb {
  background-color: #efefef;
}

.section.grey-bg {
  background-color: #efefef;
}

.mid-footer {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #333;
  background-image: -webkit-linear-gradient(315deg, rgba(0, 0, 0, .1), transparent);
  background-image: linear-gradient(135deg, rgba(0, 0, 0, .1), transparent);
  color: hsla(0, 0%, 100%, .6);
  font-size: 13px;
  font-weight: 300;
}

.pull-right {
  margin-bottom: 0px;
  float: right;
}

.pull-right.w--current {
  display: inline-block;
  float: right;
}

.copyright {
  float: left;
  clear: both;
  text-align: center;
}

.header {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 9000;
  box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, .2);
  font-weight: 300;
}

.brand {
  display: inline-block;
  padding-top: 22px;
  padding-bottom: 22px;
  float: none;
}

.brand.w--current {
  float: none;
  color: #cbb682;
}

.dropdown-link {
  width: 100%;
  -webkit-transition: color 500ms ease;
  transition: color 500ms ease;
  color: hsla(0, 0%, 100%, .6);
  font-size: 15px;
  line-height: 21px;
}

.dropdown-link:hover {
  color: #fff;
  text-decoration: none;
}

.dropdown-link.w--current {
  color: #fff;
}

.dropdown-list {
  background-color: #4aa6dd;
}

.dropdown-list.w--open {
  background-color: rgba(68, 68, 68, .9);
}

.banner-text-small {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

.banner-text-large {
  margin-bottom: 12px;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mini-slider {
  overflow: hidden;
  height: auto;
  padding-right: 5%;
  padding-left: 5%;
  background-color: transparent;
  opacity: 0.6;
}

.arrow {
  color: #fff;
  font-size: 35px;
}

.arrow.miniarrow {
  color: #8d8d8d;
}

.mini-slider-img {
  width: 25%;
  padding: 50px 15px;
}

.mask {
  text-align: center;
}

.mega-menu {
  display: none;
  width: 100%;
  margin-top: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: #666;
}

.form-col {
  margin-top: 30px;
  padding-right: 10px;
}

.input {
  width: 100%;
  min-height: 44px;
  border: 1px solid #ddd;
  -webkit-transition: border 600ms ease;
  transition: border 600ms ease;
}

.input:hover {
  border-color: #666;
}

.input:focus {
  border-color: #666;
}

.input.inline {
  min-height: 40px;
  margin-bottom: 0px;
}

.input.inline:hover {
  border-color: #666;
}

.textarea {
  min-height: 74px;
  margin-bottom: 0px;
  border: 1px solid #ddd;
}

.textarea:hover {
  border-color: #666;
}

.textarea:focus {
  border-color: #666;
}

.map {
  width: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.list-item {
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 21px;
  font-weight: 400;
}

.list {
  margin-top: 15px;
}

.blogpost-wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  clear: both;
}

.blog-element-wrapper {
  margin-bottom: 30px;
}

.tag {
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 10px 20px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  color: #666;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.tag:hover {
  border-color: transparent;
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.tag.w--current {
  font-size: 14px;
  letter-spacing: 1px;
}

.blog-social-wrapper {
  margin-top: 45px;
}

.blog-social {
  display: inline-block;
  margin-right: 10px;
  padding-right: 0px;
  float: left;
}

.fontello {
  top: 0px;
  right: 0px;
  display: inline;
  font-family: 'Corin design', sans-serif;
  font-weight: 400;
}

.fontello.fontello-cart {
  font-family: 'Corin design', sans-serif;
  font-size: 18px;
  line-height: 0px;
}

.formrow {
  margin-top: 10px;
}

.slide1 {
  background-image: url('../images/slide2.jpg');
  background-position: 50% 0px;
  background-size: cover;
}

.slide.slide2 {
  position: relative;
  overflow: hidden;
}

.about-content-wrapper {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  text-align: center;
}

.productwrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 10px 20px;
  background-color: #fff;
  box-shadow: 0 8px 20px 0 rgba(78, 78, 78, .1);
  -webkit-transition: color 500ms ease, opacity 500ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  transition: color 500ms ease, opacity 500ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  transition: color 500ms ease, opacity 500ms ease, transform 600ms ease, box-shadow 900ms ease;
  transition: color 500ms ease, opacity 500ms ease, transform 600ms ease, box-shadow 900ms ease, -webkit-transform 600ms ease;
  color: #666;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
}

.productwrapper:hover {
  box-shadow: 0 6px 20px 4px rgba(26, 26, 26, .25);
  -webkit-transform: translate(0px, -12px);
  -ms-transform: translate(0px, -12px);
  transform: translate(0px, -12px);
  color: #666;
  text-decoration: none;
}

.product-image {
  min-width: 100%;
}

.product-name {
  margin-top: 10px;
  margin-bottom: 7px;
  font-family: acta-display, sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.product-price {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
}

.dropdownlist.w--open {
  left: -342px;
  width: 940px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: hsla(0, 0%, 5%, .9);
}

.megalink {
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  color: hsla(0, 0%, 100%, .6);
  font-size: 14px;
}

.megalink:hover {
  color: #fff;
  text-decoration: none;
}

.megalink.w--current {
  color: #fff;
}

.header-fields {
  width: 50%;
  padding-top: 10px;
  float: right;
}

.nav-item {
  display: none;
  padding: 12px 10px;
  color: hsla(0, 0%, 100%, .6);
  font-size: 13px;
}

.button {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 26px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  color: #666;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: none;
}

.button:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.button._2 {
  border: 1px solid #fff;
  background-color: transparent;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2);
  color: #fff;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .9);
}

.button._2:hover {
  border-color: transparent;
  background-color: #fff;
  color: #666;
  text-shadow: none;
}

.button.footer {
  margin-bottom: 0px;
  padding: 10px 18px;
  border-color: hsla(0, 0%, 100%, .6);
  color: hsla(0, 0%, 100%, .6);
  font-size: 10px;
  line-height: 18px;
}

.button.footer:hover {
  background-color: #fff;
  color: #666;
}

.button.bespoke-cta {
  color: #000;
  font-size: 16px;
  line-height: 25px;
  text-shadow: none;
}

.footerelementwrapper {
  padding-right: 20px;
}

.bottomfooter {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #111;
  color: hsla(0, 0%, 100%, .6);
  font-size: 12px;
  font-weight: 300;
  text-align: right;
}

.new {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  background-color: #333;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  color: #fff;
  font-size: 15px;
  line-height: 52px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: none;
}

.new.pf-new-product-tag {
  top: 9px;
  right: 19px;
  width: 57px;
  height: 57px;
  background-color: #c51731;
  font-size: 18px;
  line-height: 57px;
}

.slidenav {
  display: none;
}

.formwrapper {
  position: relative;
  width: 100%;
  margin-top: 11px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.mega-col {
  padding-right: 0px;
  padding-left: 0px;
}

.social-icon {
  display: inline-block;
  border-radius: 5px;
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
}

.tint {
  background-color: #f0f0f0;
}

.price-old {
  color: #aaa;
  text-decoration: line-through;
}

.google-translate {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  height: 50px;
  max-width: 200px;
  margin-top: 8px;
  margin-right: auto;
  margin-left: auto;
}

.legal-info {
  float: left;
  clear: both;
}

.menu-button {
  float: left;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
}

.m-filter-wrapper {
  margin-bottom: 20px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border: 1px solid #ddd;
  background-color: #eee;
  color: #666;
  font-weight: 400;
}

.dropdown-icon {
  margin-right: 14px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.social-section-footer {
  display: none;
  padding-top: 20px;
  padding-bottom: 13px;
  background-color: #222;
  color: hsla(0, 0%, 100%, .6);
  text-align: center;
}

.social-section-footer.top {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.social-link {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-right: 7px;
  padding-top: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, .6);
  border-radius: 100%;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  font-family: 'Social media', sans-serif;
  color: hsla(0, 0%, 100%, .6);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .14);
}

.social-link:hover {
  border-color: transparent;
  background-color: #fff;
  color: #111;
  text-decoration: none;
  text-shadow: none;
}

.social-link.top {
  width: 38px;
  height: 44px;
  margin-right: 0px;
  margin-bottom: 0px;
  border-color: transparent;
  color: hsla(0, 0%, 100%, .6);
  font-size: 15px;
}

.social-link.top:hover {
  background-color: transparent;
  color: #cbb682;
}

.image {
  color: #e7236f;
}

.nav-right {
  display: inline-block;
  padding-right: 10px;
  float: right;
}

.banner-container {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    z-index: 10;
    padding-right: 12%;
    padding-left: 12%;
    transform: translate(0,-50%);
    color: #fff;
    font-size: 19px;
    line-height: 27px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.66);
}

.nav-container {
  position: relative;
}

.searchform-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 280px;
  margin: 25px auto 0px;
  float: left;
  background-color: transparent;
  opacity: 1;
}

.searchform {
  position: relative;
}

.searchform-close {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 48px;
  height: 48px;
  padding-top: 13px;
  background-color: #555;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: 'Corin design', sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.searchform-close:hover {
  background-color: #cbb682;
}

.top-right {
  float: right;
}

.topnav-left {
  float: left;
}

.topnav {
  display: none;
  border-top: 3px none #999;
  background-color: #eee;
}

.flex-col {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-width: 33.33%;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition-duration: 600ms;
  transition-duration: 600ms;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #f7f7f7;
}

.title-wrapper {
  position: relative;
  z-index: 10;
  margin-bottom: 60px;
  padding-right: 12%;
  padding-left: 12%;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
}

.title-wrapper.small {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 25px;
}

.title-wrapper.testimonials {
  margin-bottom: 30px;
}

.title-wrapper.no-space {
  margin-bottom: 0px;
}

.slider-button {
  display: inline-block;
  margin-top: 20px;
  margin-right: 4px;
  margin-left: 4px;
  padding: 12px 27px;
  border: 1px solid #fff;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  color: #fff;
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.slider-button:hover {
  border-color: transparent;
  background-color: #fff;
  color: #222;
  text-decoration: none;
  text-shadow: none;
}

.flex-row-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px none #000;
}

.flex-col-copy {
  padding: 9vw 6% 10vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-image: -webkit-linear-gradient(180deg, hsla(0, 0%, 100%, 0), #eee);
  background-image: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #eee);
  text-align: left;
}

.flex-col-copy.homeimage1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 340px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: -webkit-linear-gradient(0deg, hsla(0, 0%, 100%, 0), #eee), url('../images/1_1.jpg');
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #eee), url('../images/1_1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.category-title-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  padding: 15px 10px 5px;
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  line-height: 23px;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .8);
}

.main-wrapper {
  overflow: hidden;
  background-color: #fff;
}

.banner-text-medium {
  margin-bottom: 12px;
  font-size: 19px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.top-footer {
  display: none;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: #444;
  background-image: -webkit-linear-gradient(135deg, rgba(0, 0, 0, .1), transparent);
  background-image: linear-gradient(315deg, rgba(0, 0, 0, .1), transparent);
  color: hsla(0, 0%, 100%, .6);
  font-size: 14px;
  font-weight: 300;
}

.category-image {
  min-width: 100%;
  -webkit-filter: sepia(0%);
  filter: sepia(0%);
  -webkit-transition: opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
  transition: opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
  transition: filter 800ms ease, transform 900ms ease, opacity 500ms ease;
  transition: filter 800ms ease, transform 900ms ease, opacity 500ms ease, -webkit-filter 800ms ease, -webkit-transform 900ms ease;
}

.category-image:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.slider-nav {
  bottom: 20px;
  display: none;
  font-size: 12px;
}

.minislider-section {
  display: none;
  background-color: #fff;
}

.newsletter-section {
  display: none;
  padding-top: 28px;
  padding-bottom: 30px;
  background-color: #888;
  background-image: -webkit-linear-gradient(135deg, rgba(0, 0, 0, .1), transparent);
  background-image: linear-gradient(315deg, rgba(0, 0, 0, .1), transparent);
  color: #fff;
}

.slider-container {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0%;
  z-index: 10;
  padding-top: 40px;
  padding-bottom: 70px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .5);
}

.testimomonial-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url('../images/1_19.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)), url('../images/1_19.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
  -webkit-filter: none;
  filter: none;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.input-wrapper {
  position: relative;
}

.success-message {
  padding-top: 30px;
  padding-bottom: 10px;
  background-color: #666;
  color: #cbb682;
}

.error-message {
  padding-top: 20px;
  padding-bottom: 1px;
  color: #666;
  text-align: center;
}

.newsletter-heading {
  margin-bottom: 0px;
}

.nav-ad {
  display: none;
  min-height: 110px;
  background-color: #000;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/banner-ad.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url('../images/banner-ad.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, contain;
  background-repeat: repeat, repeat;
}

.slider-image1 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/0620_roomshot-thaxted.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-image2 {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/banner5.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-sidebar {
  padding-left: 10px;
}

.banner {
  position: relative;
  overflow: hidden;
  height: 725px;
  background-color: #ddd;
  text-align: center;
}

.about-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/3-1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/D908-001.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  -webkit-filter: none;
  filter: none;
}

.home {
  padding-top: 0px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

._404-img {
  display: none;
  max-width: auto;
  margin-bottom: 20px;
}

.mega-list.w--open {
  left: -315px;
  right: auto;
  width: 940px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: rgba(68, 68, 68, .9);
}

.back-top-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 9000;
  display: block;
  width: 44px;
  height: 44px;
  padding-top: 11px;
  border-radius: 100%;
  background-color: #666;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: color 500ms ease, background-color 500ms ease;
  transition: color 500ms ease, background-color 500ms ease;
  text-align: center;
}

.back-top-top:hover {
  background-color: #777;
  color: #fff;
}

.back-top-top.w--current {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9000;
  display: inline-block;
  width: 44px;
  height: 44px;
  padding: 10px 0px 0px;
  border-radius: 100%;
  background-color: #666;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: hsla(0, 0%, 100%, .6);
}

.back-top-top.w--current:hover {
  background-color: #777;
}

.about-img {
  margin-bottom: 20px;
}

.about-icon {
  margin-bottom: 10px;
}

.banner-blog {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/0620_roomshot-thaxted.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.team-content-wrapper {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  text-align: center;
}

.blog-detail-img {
  margin-bottom: 20px;
}

.blog-img-large {
  display: block;
  min-height: 731px;
  padding-bottom: 0%;
  -webkit-transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
}

.blog-img-large._1 {
  background-image: url('../images/banner3_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.blogpost-caption {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: transparent;
  text-align: left;
}

.timestamp-img {
  margin-top: -1px;
  margin-right: 6px;
  margin-left: 6px;
}

.blog-img-medium {
  display: block;
  width: 100%;
  min-height: 260px;
  -webkit-transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease;
  transition: color 500ms ease, filter 500ms ease, transform 500ms ease, -webkit-filter 500ms ease, -webkit-transform 500ms ease;
}

.blog-img-medium._1 {
  background-image: url('../images/pexels-photo-271795.jpeg');
  background-position: 50% 50%;
  background-size: cover;
}

.blog-img-medium._2 {
  background-image: url('../images/0620_roomshot-thaxted.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-medium._5 {
  background-image: url('../images/banner3_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-medium._6 {
  background-image: url('../images/D908-001.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.blog-img-medium._7 {
  background-image: url('../images/banner1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blogpost-category-link {
  position: relative;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 15px;
  padding: 7px 14px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 500ms ease;
  color: #666;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.blogpost-category-link:hover {
  background-color: #fff;
  color: #666;
  text-shadow: none;
}

.blogposts-block {
  margin-right: -20px;
  margin-left: -20px;
}

.blogpost-row {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.blogpost-text {
  padding-left: 20px;
}

.blog-img-small {
  display: block;
  padding-bottom: 100%;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.blog-img-small._1 {
  background-image: url('../images/1559_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-small._2 {
  background-image: url('../images/GDGSFDG.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-small._5 {
  background-image: url('../images/1559_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-small._6 {
  background-image: url('../images/banner3_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.blog-img-small._3 {
  background-image: url('../images/D908-001.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-img-small._4 {
  background-image: url('../images/pexels-photo-271795.jpeg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.category-link {
  display: block;
  color: hsla(0, 0%, 40%, .6);
}

.category-link:hover {
  color: #666;
}

.category-link.w--current {
  color: #666;
}

.blog-col {
  padding-right: 0px;
  padding-left: 0px;
}

.blogpost-hover {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  overflow: hidden;
  height: 0px;
  padding: 20px;
  background-color: hsla(0, 0%, 7%, .8);
  opacity: 0;
  color: #fff;
  text-align: center;
}

.center-blog {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.product-code {
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  color: #999;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
}

.social-section-header {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  color: hsla(0, 0%, 100%, .6);
  text-align: center;
}

.social-group-wrapper {
  display: block;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  text-align: center;
}

.social-feed-wrapper {
  display: inline-block;
  max-width: 400px;
  margin-right: 10px;
  margin-left: 10px;
}

.social-feed-embed {
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 6px;
}

.social-col {
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.social-col:hover {
  opacity: 0.8;
}

.social-row {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
}

.testimonial-slider {
  height: auto;
  margin-bottom: 50px;
  color: #666;
}

.testimonial-slide {
  padding-right: 9%;
  padding-left: 9%;
  color: hsla(0, 0%, 100%, .7);
  font-size: 23px;
  line-height: 31px;
  font-weight: 300;
  text-align: center;
}

.testimonial-slider-nav {
  left: 0px;
  right: 0px;
  bottom: -50px;
  font-size: 12px;
}

.cta-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/dining-room-dining-room-furniture-room-furnishing-room-design-how-to-decorate-ideas-home-decor-furnishing-chandelier-white-chair-white-fur-rug-interior-decorator-furniture-architectural-house-art-grap.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/dining-room-dining-room-furniture-room-furnishing-room-design-how-to-decorate-ideas-home-decor-furnishing-chandelier-white-chair-white-fur-rug-interior-decorator-furniture-architectural-house-art-grap.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
}

.testimonials-element-wrapper {
  text-align: left;
}

.social-icon-wrapper {
  float: none;
}

.footer-img {
  display: none;
  min-width: 50%;
  float: none;
  text-align: left;
}

.footer-usps {
  display: none;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #555;
  background-image: -webkit-linear-gradient(135deg, rgba(0, 0, 0, .1), transparent);
  background-image: linear-gradient(315deg, rgba(0, 0, 0, .1), transparent);
  color: hsla(0, 0%, 100%, .6);
  font-size: 14px;
  font-weight: 300;
}

.usp1 {
  display: inline-block;
  width: 33.33%;
  padding-right: 20px;
  padding-left: 20px;
  float: left;
  text-align: center;
}

.banner-cta {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #666;
  color: hsla(0, 0%, 100%, .6);
}

.banner-usp {
  padding-right: 20px;
  padding-left: 48px;
  text-align: left;
}

.banner-cta-icon {
  position: absolute;
  left: 10px;
  top: 20px;
  float: left;
}

.usp-img {
  opacity: 0.6;
}

.footer-payment-img {
  display: none;
}

.midnav {
  border-top: 3px none #999;
  background-color: #fff;
}

.custom-stock-message {
  margin-top: 6px;
  margin-bottom: 6px;
  color: #41bd3c;
}

.flex-parent {
  display: block;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.flex-child {
  position: relative;
  display: inline-block;
  width: 64%;
  margin-right: 1%;
  margin-bottom: 2%;
  margin-left: 1%;
  float: none;
}

.flex-child.small {
  width: 31%;
}

.img-overlay {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 600px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #eee;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.img-overlay._1 {
  background-image: url('../images/1559_1.jpg'), url('../images/example-bg.png');
  background-position: 50% 50%, 0px 0px;
  background-size: cover, 125px;
  background-repeat: no-repeat, repeat;
}

.img-overlay._2 {
  background-image: url('../images/banner3_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.img-overlay._3 {
  background-image: url('../images/0620_roomshot-thaxted.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.img-overlay._4 {
  background-image: url('../images/pexels-photo-271795.jpeg'), url('../images/example-bg.png');
  background-position: 50% 50%, 0px 0px;
  background-size: cover, 125px;
  background-repeat: no-repeat, repeat;
}

.img-overlay._5 {
  background-image: url('../images/banner1.jpg'), url('../images/example-bg.png');
  background-position: 50% 50%, 0px 0px;
  background-size: cover, 125px;
  background-repeat: no-repeat, repeat;
}

.img-overlay._6 {
  background-image: url('../images/GDGSFDG.jpg'), url('../images/example-bg.png');
  background-position: 50% 50%, 0px 0px;
  background-size: cover, 125px;
  background-repeat: no-repeat, repeat;
}

.center-flex {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 20px 30px 30px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  color: #fff;
  font-size: 19px;
  line-height: 27px;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, .3);
}

.center-flex._1 {
  position: absolute;
  left: 0%;
  right: 0px;
  bottom: 0px;
  z-index: 10;
}

.center-flex._3 {
  text-align: center;
}

.center-flex._4 {
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .5);
}

.center-flex._6 {
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: center;
}

.products {
  padding-right: 80px;
  padding-left: 80px;
}

.row-portfolio {
  display: block;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
}

.elementwrapper {
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, .2);
  text-align: center;
}

.productname {
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 19px;
}

.productprice {
  display: none;
  margin-bottom: 10px;
}

.newlabel {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 52px;
  height: 52px;
  padding-top: 16px;
  border-radius: 100%;
  background-color: #333;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  color: #fff;
  font-size: 15px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.productimg {
  margin-bottom: 40px;
}

.brandsection {
  position: relative;
  padding: 130px 20px;
  background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/banner3_1.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../images/banner3_1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  color: #fff;
  font-size: 19px;
  line-height: 27px;
  font-weight: 300;
  text-align: center;
}

.home-element-wrapper {
  display: inline-block;
  width: 33.33%;
  margin-bottom: 60px;
  padding-right: 10px;
  padding-left: 10px;
  float: left;
  font-size: 17px;
  font-weight: 300;
}

.home-icon {
  margin-bottom: 10px;
  padding: 10px;
}

.text-align-center {
  text-align: center;
}

.product-block {
  padding-right: 0px;
  padding-left: 0px;
}

.footer-column {
  text-align: right;
}

.topcart {
  overflow: hidden;
  height: 0px;
  clear: both;
}

.bespoke-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('../images/1559_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.full-width-container {
  padding-right: 50px;
  padding-left: 50px;
}

.full-width-container.text-align-left {
  text-align: left;
}

.full-width-container.page-header {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.title-wrapper-copy {
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 19px;
  line-height: 27px;
}

.title-wrapper-copy.small {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 25px;
}

.title-wrapper-copy.testimonials {
  margin-bottom: 30px;
}

.title-wrapper-copy.no-space {
  margin-bottom: 0px;
}

.full-width-inner-container {
  padding: 40px 10px;
}

.full-width-inner-container.pt-b20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.step-col {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bespoke-img {
  margin-bottom: 40px;
}

.bespoke-img._2 {
  margin-bottom: 30px;
}

.link {
  display: inline-block;
}

.text-span {
  font-family: Fonts, sans-serif;
}

.text-block {
  position: static;
}

.text-block-2 {
  position: absolute;
  right: 13%;
  bottom: 192px;
  width: 85px;
  height: auto;
  border-radius: 3px;
  background-color: #000;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  color: #fff;
  font-size: 15px;
  line-height: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-block-3 {
  position: absolute;
  left: 13%;
  bottom: 192px;
  width: 100px;
  border-radius: 3px;
  background-color: #000;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  direction: ltr;
  color: #fff;
  line-height: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.text-block-4 {
  font-size: 120px;
  line-height: 120px;
  text-align: center;
}

.text-block-5 {
  color: #1b78e2;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-5.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.text-block-6 {
  color: #fc9505;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-6.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.text-block-7 {
  color: #c7112c;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-7.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.heading {
  margin-bottom: 12px;
  color: #fff;
}

.heading.banner-text-large {
  color: #343434;
  text-shadow: none;
}

.heading.banner-text-large.copy {
  color: #fff;
  text-shadow: 1px 1px 3px #000;
}

.heading.banner-text-large.smaller {
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 34px;
}

.paragraph {
  color: #fff;
}

.paragraph.banner-text-small {
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  color: #343434;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  text-shadow: none;
}

.paragraph.banner-text-small.copy {
  color: #fff;
  font-size: 20px;
  text-shadow: 1px 1px 3px #000;
}

.text-block-8 {
  color: #1b78e2;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-8.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.text-block-9 {
  color: #c7112c;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-9.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.text-block-10 {
  color: #fc9505;
  font-size: 100px;
  line-height: 85px;
  text-align: center;
}

.text-block-10.black {
  color: #000;
  font-size: 115px;
  line-height: 119px;
}

.text-block-11 {
  font-size: 120px;
  line-height: 120px;
  text-align: center;
}

.paragraph-2 {
  font-weight: 400;
}

.div-block-3 {
  max-height: 500px;
  min-height: 370px;
  background-image: url('../images/banner1_2.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
}

.div-block-4 {
  min-height: 370px;
  background-image: url('../images/banner2.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.section-2 {
  background-color: #f0f0f0;
}

.div-block-5 {
  min-height: 370px;
  background-image: url('../images/banner1_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.text-block-12 {
  margin-bottom: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 50px;
  line-height: 69px;
  text-transform: uppercase;
}

.div-block-6 {
  position: relative;
  min-height: 500px;
  background-image: url('../images/1559_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.text-block-13 {
  position: relative;
  left: 20px;
  right: 0px;
  bottom: -430px;
  width: 305px;
  padding-bottom: 5px;
  background-color: rgba(36, 122, 223, .7);
  color: #fff;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  text-shadow: 1px 1px 6px #000;
}

.paragraph-3 {
  position: relative;
  left: 20px;
  right: 0px;
  bottom: -430px;
  width: 300px;
  background-color: rgba(36, 122, 223, .7);
  color: #fff;
  font-size: 25px;
  line-height: 45px;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
}

.heading-2 {
  border-bottom: 2px none #e0e0e0;
  color: #fff;
}

.heading-2.copy {
  border-style: none none solid;
  color: #000;
}

.heading-3 {
  border-bottom: 2px solid #e0e0e0;
}

.text-block-14 {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 0px;
  padding-left: 20px;
  font-family: Lato, sans-serif;
  font-size: 35px;
  line-height: 43px;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
}

.div-block-7 {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 188px;
  margin: 10px;
  padding: 10px;
  background-color: hsla(0, 0%, 100%, .75);
  text-align: center;
}

.div-block-7.copy {
  margin: 0px;
  padding: 40px;
  background-color: rgba(0, 0, 0, .5);
}

.div-block-8 {
  position: relative;
  bottom: -155px;
  padding: 40px;
  background-color: rgba(0, 0, 0, .5);
  color: #fff;
  text-shadow: 1px 1px 6px #000;
}

.heading-4 {
  text-transform: uppercase;
}

.heading-5 {
  font-family: 'Spectral SC', sans-serif;
  font-weight: 200;
  text-transform: none;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, .66);
}

.heading-5.spectralsc {
  font-family: 'Spectral SC', sans-serif;
}

.heading-5.merriweather {
  font-family: Merriweather, serif;
}

.heading-5.martel {
  font-family: Martel, sans-serif;
}

.step-title-container {
  padding: 0px 15px;
  background-color: #343434;
  direction: ltr;
  color: #fff;
}

.image-2 {
  width: 100%;
}

.separator {
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.image-block-1 {
  height: 600px;
  background-image: url('../images/GDGSFDG.jpg');
  background-position: 50% 75%;
  background-size: cover;
}

.image-block-2 {
  height: 600px;
  background-image: url('../images/pexels-photo-271795.jpeg');
  background-position: 50% 75%;
  background-size: cover;
}

.image-block-3 {
  height: 600px;
  background-image: url('../images/0620_roomshot-thaxted.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-block-4 {
  height: 600px;
  background-image: url('../images/banner3_1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-block-5 {
  height: 600px;
  background-image: url('../images/banner2.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-block-6 {
  height: 600px;
  background-image: url('../images/banner1.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-block-7 {
  height: 600px;
  background-image: url('../images/banner5.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-block-8 {
  height: 300px;
  background-image: url('../images/dining-room-dining-room-furniture-room-furnishing-room-design-how-to-decorate-ideas-home-decor-furnishing-chandelier-white-chair-white-fur-rug-interior-decorator-furniture-architectural-house-art-grap.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.step-heading {
  letter-spacing: 1px;
}

.heading-7 {
  letter-spacing: 1px;
}

.heading-8 {
  letter-spacing: 1px;
}

.heading-9 {
  letter-spacing: 1px;
}

.heading-10 {
  letter-spacing: 1px;
}

.heading-11 {
  letter-spacing: 1px;
}

.step-title {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px none #e0e0e0;
  color: #fff;
  font-size: 26px;
  line-height: 30px;
}

.step-title.copy {
  border-style: none none solid;
  color: #000;
}

.bespoke-image-main {
  position: relative;
  min-height: 600px;
  background-image: url('../images/1559_1.jpg');
  background-position: 50% 85%;
  background-size: cover;
}

.bespoke-title {
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
}

.text-span-2 {
  font-family: Italianno, sans-serif;
  font-size: 65px;
  font-weight: 400;
  letter-spacing: 0px;
  text-transform: capitalize;
}

.bespoke-image-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 26px;
  border: 1px solid #666;
  background-color: transparent;
  -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
  color: #343434;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: none;
}

.bespoke-image-title:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.bespoke-image-title._2 {
  border: 1px solid #fff;
  background-color: transparent;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .2);
  color: #fff;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, .9);
}

.bespoke-image-title._2:hover {
  border-color: transparent;
  background-color: #fff;
  color: #666;
  text-shadow: none;
}

.bespoke-image-title.footer {
  margin-bottom: 0px;
  padding: 10px 18px;
  border-color: hsla(0, 0%, 100%, .6);
  color: hsla(0, 0%, 100%, .6);
  font-size: 10px;
  line-height: 18px;
}

.bespoke-image-title.footer:hover {
  background-color: #fff;
  color: #666;
}

.bespoke-image-title.bespoke-cta {
  color: #000;
  font-size: 16px;
  line-height: 25px;
  text-shadow: none;
}

.bespoke-image-heading {
  margin-bottom: 0px;
  padding: 10px 26px;
  border: 1px solid #666;
  font-size: 16px;
  line-height: 25px;
  text-transform: uppercase;
}

html.w-mod-js *[data-ix="megamenudisplaynone"] {
  display: none;
}
@media (max-width: 1024px) {
    .text-block-14 {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 40px;
    }
}
@media (max-width: 991px) {
  .nav-link.w--current {
    display: inline-block;
  }
  .nav-link._2 {
    padding-top: 26px;
    padding-bottom: 26px;
  }
  .nav-menu {
    display: inline-block;
    float: left;
  }
  .section.products {
    padding-right: 10px;
    padding-left: 10px;
  }
  .mid-footer {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .copyright {
    float: none;
  }
  .header {
    font-size: 20px;
  }
  .brand {
    padding-left: 0px;
    float: left;
  }
  .brand.w--current {
    float: left;
  }
  .pull-left {
    float: left;
  }
  .pull-left.menu-btn {
    padding: 25px;
  }
  .mini-slider-img {
    width: 25%;
  }
  .map {
    margin-top: 30px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .formrow {
    margin-top: 20px;
  }
  .about-content-wrapper {
    top: 100%;
  }
  .dropdownlist.w--open {
    left: -195px;
    right: 0px;
  }
  .footerelementwrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .bottomfooter {
    padding-bottom: 20px;
    text-align: center;
  }
  .google-translate {
    margin-top: 0px;
  }
  .legal-info {
    margin-bottom: 8px;
    float: none;
    text-align: center;
  }
  .social-section-footer.top {
    display: none;
    clear: both;
  }
  .nav-right {
    display: inline-block;
  }
  .searchform-wrapper {
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
    float: none;
  }
  .flex-col {
    min-width: 50%;
  }
  .newsletter-section {
    padding-top: 40px;
  }
  .newsletter-column {
    margin-bottom: 20px;
  }
  .mega-list.w--open {
    left: -105px;
    display: block;
    max-width: 768px;
    margin-left: 11px;
  }
  .team-content-wrapper {
    top: 100%;
  }
  .blog-img-large {
    min-height: 210px;
    padding-bottom: 66.66%;
  }
  .blog-img-medium {
    min-height: 210px;
    padding-bottom: 66.66%;
  }
  .blogpost-category-link {
    margin-bottom: 7px;
  }
  .blogposts-block {
    margin-right: -5px;
    margin-left: -5px;
  }
  .blogpost-row {
    padding-right: 5px;
    padding-left: 5px;
  }
  .blogpost-text {
    padding-left: 0px;
  }
  .blog-img-small {
    margin-bottom: 20px;
  }
  .category-link {
    margin-bottom: 7px;
  }
  .social-section-header {
    display: none;
  }
  .bottom-footer-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .banner-usp {
    padding-right: 0px;
  }
  .footer-payment-img {
    margin-bottom: 15px;
  }
  .center-flex {
    padding-right: 30px;
    padding-left: 30px;
  }
  .elementwrapper {
    margin-bottom: 20px;
  }
  .full-width-container.text-align-left.grey-bg {
    text-align: center;
  }
  .text-block-5 {
    margin-bottom: 20px;
  }
  .text-block-6 {
    margin-bottom: 20px;
  }
  .text-block-7 {
    margin-bottom: 20px;
  }

  .text-span-2 {
    font-size: 60px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  h1 {
    font-size: 30px;
    line-height: 38px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    margin-top: 20px;
  }
  a.w--current {
    float: none;
  }
  .nav {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    text-align: left;
  }
  .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    color: hsla(0, 0%, 100%, .6);
  }
  .nav-link:hover {
    color: #fff;
  }
  .nav-link.w--current {
    display: block;
    color: #fff;
  }
  .nav-link.nav-dropdown {
    text-align: left;
  }
  .nav-link.nav-dropdown.w--open {
    color: #fff;
  }
  .nav-link.top {
    display: inline-block;
    text-align: center;
  }
  .nav-link.top.pull-right {
    float: none;
    text-align: center;
  }
  .nav-link.top.pull-right.show-mobile {
    display: inline-block;
    padding-top: 9px;
  }
  .nav-link._2 {
    padding-top: 17px;
    padding-bottom: 25px;
    font-size: 14px;
  }
  .nav-link.mid {
    display: inline-block;
    padding-top: 28px;
    padding-bottom: 28px;
    text-align: center;
  }
  .nav-menu {
    float: none;
    background-color: rgba(68, 68, 68, .9);
  }
  .nav-menu.top {
    position: static;
    display: block;
    margin-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
    opacity: 1;
  }
  .login-btn {
    top: 0px;
  }
  .slider {
    max-height: 500px;
    min-height: 300px;
  }
  .section {
    overflow: hidden;
    padding: 50px 10px;
  }
  .section.tint.bespoke {
    padding-bottom: 40px;
  }
  .section.cta {
    text-align: center;
  }
  .section.blog {
    padding-right: 10px;
    padding-left: 10px;
  }
  .mid-footer {
    padding: 20px 10px 40px;
  }
  .pull-right {
    float: right;
  }
  .brand {
    max-width: 200px;
    padding-left: 0px;
    text-align: center;
  }
  .pull-left.menu-btn {
    padding: 18px 19px 19px;
  }
  .pull-left.menu-btn.w--open {
    background-color: #dedfde;
  }
  .banner-text-small {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-text-large {
    margin-bottom: 8px;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: 0px;
  }
  .mini-slider {
    height: auto;
  }
  .mini-slider-img {
    width: 33.33%;
  }
  .form {
    padding-right: 0px;
  }
  .form-col {
    padding-right: 0px;
  }
  .input {
    width: 100%;
  }
  .list-item {
    float: left;
  }
  .blogpost-wrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .blog-element-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
  .blog-social-wrapper {
    margin-top: 130px;
  }
  .disquis {
    margin-top: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .about-content-wrapper {
    display: block;
    max-width: 320px;
    margin: 20px auto;
  }
  .productwrapper {
    display: block;
    max-width: 300px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
  }
  .dropdownlist.w--open {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
  }
  .hidearrow {
    display: none;
  }
  .header-fields {
    display: none;
    width: 100%;
    opacity: 0;
  }
  .nav-item {
    display: none;
  }
  .footerelementwrapper {
    padding-right: 0px;
  }
  .bottomfooter {
    padding-right: 20px;
    padding-left: 20px;
  }
  .google-translate {
    display: none;
  }
  .menu-button {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    float: right;
    border: 1px solid #ddd;
    color: #aaa;
  }
  .menu-button.w--open {
    border-color: transparent;
    background-color: #666;
    color: #fff;
  }
  .m-filter-wrapper {
    display: none;
    opacity: 0;
  }
  .social-section-footer.top {
    display: none;
  }
  .nav-right {
    padding-right: 20px;
    float: right;
  }
  .banner-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .nav-container {
    text-align: center;
  }
  .searchform-wrapper {
    position: relative;
    display: block;
    max-width: 100%;
    padding-top: 0px;
    clear: both;
  }
  .searchform-close {
    margin-top: -7px;
  }
  .topnav-left {
    display: inline;
    float: none;
  }
  .flex-col {
    min-width: 100%;
  }
  .flex-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .title-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slider-button {
    margin-top: 15px;
  }
  .flex-col-copy {
    padding: 50px 20px;
  }
  .banner-text-medium {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 4px;
  }
  .top-footer {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .category-image:hover {
    opacity: 1;
  }
  .slider-container {
    padding: 30px 20px 40px;
    text-align: center;
  }
  .newsletter-column {
    margin-top: -20px;
    margin-bottom: 30px;
  }
  .blog-sidebar {
    padding-left: 0px;
  }
  .banner {
    height: 280px;
  }
  .mega-list.w--open {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-left: 0px;
  }
  .row {
    text-align: center;
  }
  .team-content-wrapper {
    display: block;
    max-width: 320px;
    margin: 20px auto;
  }
  .blogpost-caption {
    position: relative;
  }
  .blogpost-text {
    position: relative;
  }
  .blog-img-small {
    min-height: 210px;
    padding-bottom: 66.66%;
  }
  .blogpost-hover {
    position: relative;
    background-color: hsla(0, 0%, 7%, .9);
  }
  .product-code {
    margin-bottom: 10px;
    color: #777;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
  }
  .social-group-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .social-feed-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .social-col {
    margin-bottom: 20px;
  }
  .testimonial-slider {
    margin-bottom: 30px;
  }
  .testimonial-slide {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 19px;
    line-height: 27px;
  }
  .testimonial-slider-nav {
    font-size: 11px;
  }
  .testimonial-arrow-right {
    display: none;
  }
  .testimonial-arrow-left {
    display: none;
  }
  .footer-usps {
    padding-top: 35px;
    padding-bottom: 25px;
  }
  .usp1 {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .banner-usp {
    padding-left: 58px;
  }
  .banner-cta-icon {
    left: 20px;
  }
  .flex-child {
    display: block;
    width: auto;
    margin-right: 20px;
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .flex-child.small {
    width: auto;
    margin-bottom: 30px;
  }
  .img-overlay {
    display: block;
    height: 480px;
    margin-right: auto;
    margin-left: auto;
  }
  .center-flex {
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding-bottom: 30px;
    background-image: -webkit-linear-gradient(270deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5));
  }
  .center-flex._1 {
    position: relative;
    left: 0%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .center-flex._3 {
    top: 0%;
    -webkit-transform: translate(0px, 0%);
    -ms-transform: translate(0px, 0%);
    transform: translate(0px, 0%);
    text-align: center;
  }
  .center-flex._4 {
    top: -1px;
  }
  .home-element-wrapper {
    width: 100%;
    margin-bottom: 25px;
  }
  .full-width-container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .title-wrapper-copy {
    padding-right: 10px;
    padding-left: 10px;
  }
  .full-width-inner-container.pt-b20 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .bespoke-img._2 {
    margin-bottom: 30px;
  }
  .column {
    margin-bottom: 10px;
  }
  .column-2 {
    margin-bottom: 10px;
  }
  .column-3 {
    margin-bottom: 10px;
  }
  .column-4 {
    margin-bottom: 10px;
  }
  .text-block-14 {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 0px;
    font-size: 27px;
    line-height: 35px;
    text-align: center;
  }
  .step-title-container {
    margin-top: -20px;
  }
  .image-block-1 {
    height: 480px;
    margin-bottom: 20px;
  }
  .image-block-2 {
    height: 480px;
  }
  .image-block-3 {
    height: 480px;
    margin-bottom: 20px;
  }
  .image-block-4 {
    height: 480px;
  }
  .image-block-5 {
    height: 480px;
    margin-bottom: 20px;
  }
  .image-block-6 {
    height: 480px;
    margin-bottom: 20px;
  }
  .image-block-7 {
    height: 480px;
  }
  .bespoke-image-main {
    min-height: 480px;
    margin-bottom: 20px;
  }
  .text-span-2 {
    font-size: 52px;
    letter-spacing: 0px;
  }
}

@media (max-width: 479px) {
  h1 {
    margin-left: 0px;
  }
  .nav.top {
    text-align: center;
  }
  .nav-link {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 21px;
    font-size: 14px;
    line-height: 20px;
  }
  .nav-link.top {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 12px;
  }
  .nav-link._2 {
    width: 44px;
    padding-top: 17px;
    padding-bottom: 20px;
  }
  .nav-link.mid {
    padding: 26px 6px;
    font-size: 12px;
  }
  .brand {
    max-width: 170px;
    margin-right: auto;
    padding-left: 0px;
  }
  .dropdown-link {
    font-size: 14px;
  }
  .banner-text-large {
    font-size: 21px;
    line-height: 29px;
  }
  .mini-slider-img {
    width: 100%;
    text-align: center;
  }
  .map {
    position: relative;
  }
  .list-item {
    width: 100%;
  }
  .blog-social-wrapper {
    margin-top: 170px;
  }
  .blog-social {
    margin-right: -14px;
  }
  .about-content-wrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .header-fields {
    float: none;
  }
  .nav-item {
    display: none;
    float: none;
  }
  .social-section-footer.top {
    display: none;
  }
  .nav-right {
    padding-right: 10px;
  }
  .searchform-wrapper {
    margin-top: 0px;
  }
  .top-right {
    float: right;
  }
  .topnav-left {
    display: inline;
    float: none;
  }
  .slider-button {
    float: none;
  }
  .slider-nav {
    bottom: 0px;
  }
  .slider-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .team-content-wrapper {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .social-feed-wrapper {
    max-width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .testimonial-slide {
    padding-right: 10px;
    padding-left: 10px;
  }
  .testimonial-slider-nav {
    bottom: -40px;
  }
  .usp1 {
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .div-block-2 {
    font-size: 18px;
    line-height: 26px;
  }
  .center-flex {
    padding-right: 10px;
    padding-left: 10px;
  }
  .hide-mobile {
    display: none;
  }
  .full-width-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .full-width-inner-container.pt-b20 {
    padding-right: 0px;
    padding-left: 0px;
  }
  .text-block-13 {
    width: 44px;
  }
  .paragraph-3 {
    bottom: -390px;
    width: 218px;
  }
  .div-block-7 {
    width: auto;
  }
  .bespoke-image-main {
    min-height: 300px;
  }
}

@font-face {
  font-family: 'Social media';
  src: url('../fonts/social-media.woff2') format('woff2'), url('../fonts/social-media.eot') format('embedded-opentype'), url('../fonts/social-media.woff') format('woff'), url('../fonts/social-media.ttf') format('truetype'), url('../fonts/social-media.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Corin design';
  src: url('../fonts/corin-design.woff2') format('woff2'), url('../fonts/corin-design.eot') format('embedded-opentype'), url('../fonts/corin-design.woff') format('woff'), url('../fonts/corin-design.ttf') format('truetype'), url('../fonts/corin-design.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fonts';
  src: url('../fonts/fonts.woff2') format('woff2'), url('../fonts/fonts.eot') format('embedded-opentype'), url('../fonts/fonts.woff') format('woff'), url('../fonts/fonts.ttf') format('truetype'), url('../fonts/fonts.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}