/* Custom CSS */
html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

.input-wrapper .login-btn {
    height: 40px;
}

footer h3, .banner-usp h3 {
    color: white;
}
.video-img {
    position: relative;
    width: 100%;
    height: 91px;
}
.video-img img{
    height: 100%;
}
.bespokePageWrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 20px;
}
.bespokeItem {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    grid-column: span 3;
}
.bespokePageWrapper .bespokeItem:nth-child(10n-6), .bespokePageWrapper .bespokeItem:nth-child(10n) {
    grid-column: span 4;
}
.bespokePageWrapper .bespokeItem:nth-child(5n-3) {
    grid-column: span 6;
}
.bespokePageWrapper .bespokeItem:nth-child(10n-5), .bespokePageWrapper .bespokeItem:nth-child(10n-1) {
    grid-column: span 8;
}
.bespokeItem img, .bespokeItem {
    width: 100%;
    height: 600px !important;
    object-fit:cover;
}
.bespokePageWrapper .grid-text-center {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
}
    @media (max-width: 991px) {
        .video-img {
            height: 138px;
        }

        div#paymentMethods > .w-col {
            width: 100%;
            margin-bottom: -20px;
        }

        .w-container {
            padding: 0px 10px;
        }
    }

    .brand {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 240px;
    margin-left: -10px;
    margin-right: -10px;
}
@media (max-width: 767px) {
    .bespokeItem {
        grid-column: span 12!important;
    }
    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .product-detail-description.p {
        margin-bottom: 30px;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .w-container.product-list-head {
        padding-bottom: 20px;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }

    .brand {
        max-width: 200px;
        margin-left: -15px;
        margin-right: -15px;
    }
}
@media (max-width: 769px) {
h2 {
    font-size: 24px;
    line-height: 32px;
}
    .catalogue.project-column {
    width:100%;
    }
    .w-form.results-filter-wrapper {
    display:none;
    }
.bespoketab img {
    height: 470px;
    width: 100%;
}
    .full-width-inner-container.pt-b20.gal .img-overlay, .bespokeItem img, .bespokeItem {
        height: 375px!important;
    }
}
@media screen and (max-width: 479px) {
    .pagination {
        padding: 0px 10px;
    }
}

/*/
  /*New*/
.pf-page-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fff;
}

.pf-btn.pf-btn-default.pf-button-with-input {
    padding: 8px 10px !important;
    top: -3px;
}

#pf-product-details-page .pf-product-image-thumb {
    padding: 20px 0px !important;
    background: white;
}

.breadcrumb {
    margin-bottom: 30px !important;
}

footer ul li {
    list-style: none;
}

.account-row {
    margin-bottom: 30px;
}

span.w-button.pageno {
    background-color: transparent;
    color: #444;
}

.brand-filter {
    display: none;
}

html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
.w-list-unstyled ul li {
    margin-left: 0;
    list-style: none !important;
}

.brand-filter {
    display: none;
}

footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1170px;
    padding: 0px 20px;
}

.input-wrapper .login-btn {
    height: 40px;
}

footer h3, .banner-usp h3 {
    color: white;
}


@media (max-width: 991px) {

    div#paymentMethods > .w-col {
        width: 100%;
        margin-bottom: -20px;
    }

    .w-container {
        padding: 0px 10px;
    }
}

@media (max-width: 767px) {
    .pf-page-wrapper {
        overflow: hidden;
        padding: 50px 10px;
    }

    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .product-detail-description.p {
        margin-bottom: 30px;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .w-container.product-list-head {
        padding-bottom: 20px;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }
}


@media screen and (max-width: 479px) {
    .pagination {
        padding: 0px 10px;
    }
}
/*Mav's fixes*/
/*white button*/
.banner-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .whitebutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid white;
    background-color: transparent;
    -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    color: white;
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: none;
}

    .banner-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover, .whitebutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: white;
        color: #666;
    }

/*grey button*/
.banner-container.grey a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .greybutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .w-button.contact {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid #666;
    background-color: transparent;
    -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: none;
}

    .banner-container.grey a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover, .greybutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover, .w-button.contact:hover {
        color: white;
        background-color: #666;
    }
/*contact us*/
.mbss-emailForm-wrapper input:hover {
    border-color: #666;
}

.contactaboutbanner {
    background-size: cover;
    background-position: 50% 50%;
}

.about-icon {
    margin-bottom: 15px;
}


/*Blas fixes*/
#myCarousel-1-1 .mbss-editmode {
    position: absolute;
}

#myCarousel-1-1 ul.mbss-textArea-btns {
    top: 70%;
    left: 6%;
}

#myCarousel-1-1 ul.redactor-toolbar {
    left: 3% !important;
}

.project-column {
    margin-bottom: 25px;
}

.footerelementwrapper p {
    margin-bottom: 0px;
}

.gridcat {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.product-info-match-height {
    margin-top: 12px;
}

.section.products, .section.top-categories {
    padding-top: 40px;
}

.lg-backdrop.in {
    z-index: 9999999 !important;
}

.lg-outer.lg-visible {
    z-index: 9999999 !important;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.flex-child a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

    .flex-child a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #fff;
        color: #666;
        text-decoration: none;
    }

.ui-autocomplete {
    z-index: 99999 !important;
}

.list-view-add-cart-wrapper .button {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 1px;
    text-shadow: none;
    border: 1px solid #666;
    text-transform: uppercase;
}

    .list-view-add-cart-wrapper .button:hover {
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.social-icon-wrapper {
    margin-top: 15px;
}

.createaccount {
    margin-top: 50px;
}

    .createaccount .w-container {
        margin-bottom: 50px;
    }

.productcontent {
    cursor: zoom-in;
}

.brandsection .button-wrapper a:hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-shadow: none;
}

#emailForm-31-6 {
    margin-top: 18px
}

    #emailForm-31-6 .textarea {
        min-height: 134px;
    }

    #emailForm-31-6 .green-btn {
        float: right;
    }


#bag-opener .fontello {
    width: 14px;
}

/*End blas*/
.img-overlay {
    display: block !important;
    background-size: cover;
    background-position: 50% 50% !important;
}

.mbss-emailForm-wrapper input:focus {
    border-color: #666;
}

.section.top-categories {
    padding-bottom: 0px;
}
/*IE Explorer Fix*/
.banner-container.w-container {
    max-width: inherit;
}

/*blog*/
.blog-details img {
    margin-bottom: 15px;
}

@media screen and (min-width: 1200px) {
    .blog-img-large {
        min-height: 786px !important;
    }
}
/*product pages tidy up*/
h1 {
    font-family: 'Open Sans',sans-serif;
}

.pf-product-list-page, #pf-product-details-page, #pf-product-listing {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.pf-breadcrumbs .pf-normal-link, .breadcrumb .pf-normal-link {
    text-decoration: none;
}

.productListingMainHeading {
    margin-top: 10px;
    margin-bottom: 20px;
}

.pf-brand-info {
    display: none;
}

#pf-product-details-page .breadcrumb {
    margin-bottom: 30px;
}
/*Rama*/
.blog-layout-full-width-featured-posts .blogpost-caption {
    background-color: transparent !important;
    position: relative !important;
    color: #333 !important;
}

.blog-layout-full-width-featured-posts .blogpost-category-link {
    display: inline-block !important;
    margin-right: 7px !important;
    margin-bottom: 15px !important;
    padding: 7px 14px !important;
    border: 1px solid #666 !important;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #666 !important;
    font-size: 11px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
    background-color: transparent !important;
}

.blog-layout-full-width-featured-posts h2 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-size: 32px !important;
    line-height: 40px !important;
    font-weight: 400 !important;
    letter-spacing: 1px !important;
}

.nav-link, h2, h3, h4 {
    text-transform: uppercase !important;
}

.categoryblog li {
    list-style: none;
}

.categoryblog {
    margin-bottom: 25px;
}

.contactdesc li {
    list-style: none;
}
/*Gallery*/
#grid {
    margin-bottom: 100px;
}

.grid-text-center {
    position: relative;
    left: 0;
    margin-top: -50px;
    padding-right: 20px;
    padding-left: 20px;
}
.grid-text-center p{
    font-weight:600;
    margin-bottom:5px;
}

.grid-sizer {
    width: 25%;
    float: left;
}

.grid-item {
    position: relative;
    overflow: hidden;
    width: 33.33%;
    margin: 0;
    float: left;
}


.grid-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
}
.grid-item:hover .grid-image {
    transform: scale(1.2);
    cursor: zoom-in;
    transition: all 1s ease;
}
.image-placeholder.grid-image {
    max-width:400px;
    max-height:400px;
}
.grid-text {
    width: 100%;
    height: 0px;
    padding-top: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFF;
    font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
}

.grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 25px;
}
.grid-container.w-container {
    padding:0;
}
.mfp-bottom-bar .mfp-title {
    text-align: center;
    padding-right: 0;
}
.mfp-bottom-bar .mfp-title h1{
    font-size:28px;
    line-height:36px;
}
.mfp-bg {
    opacity: 0.6;
}
.grid-wrapper .addListItem2Btn {
    right: -80px !important;
}

.grid-wrapper .deleteListItem2Btn {
    bottom: 0px !important;
}

.grid-text-center .re-table {
    display: none !important;
}

.grid-text-center .re-fontcolor {
    display: none !important;
}

.grid-text-center .re-table {
    display: none !important;
}

.grid-text-center .re-lists {
    display: none !important;
}

.grid-text-center .re-deleted {
    display: none !important;
}
.title-wrapper-variant {
    text-align: center;
}
.variant-enquire {
    line-height: 21px;
}
@media (max-width: 992px) {
    .grid-item {
        width: 48%;
    }

    .hidemobile {
        display: none;
    }

    .about-content-wrapper {
        display: block;
        max-width: 100%;
        margin: 20px auto;
    }

    .center-flex {
        position: absolute !important;
    }
}

@media (max-width: 479px) {
    .grid-item {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 15px;
        margin-left: 0%;
    }
}
/*End Gallery*/
.title-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid #666;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    text-shadow: none;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .title-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.section.cta-bg .redactor-toolbar-box, .section.cta-bg .mbss-textArea-btns {
    left: 50px;
}

.legal-info {
    float: right;
}

.section.cta-bg .editImageX {
    top: 0 !important;
    left: 100px !important;
}

.top-right .nav-link {
    text-transform: none !important;
}

.info-wrapper-right {
    text-align: center;
}
.info-wrapper-right a{
    text-decoration:underline;
    font-weight:600;
}

.brandsection .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-shadow: none;
}

.sub-cat-holder-match-height .m-product-name {
    margin-top: 5px;
}

.col-subcat {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-decoration: none;
}

.blog-layout-full-width-featured-posts .blogpost-category-link:hover {
    background-color: #666 !important;
    color: #fff !important;
    text-decoration: none !important;
}

.pf-favs-table .pf-qty-btn {
    font-size: 18px;
}

#pf-product-details-page .pf-product-image-thumb {
    padding: 0px !important;
}

    #pf-product-details-page .pf-product-image-thumb img {
        width: 100%;
    }

#pf-product-list-page .breadcrumb {
    margin-bottom: 0px !important;
}

#pf-product-list-page .section {
    padding-top: 0px;
}

.filter-subdropdown-link.pf-filter-item {
    padding: 0px 10px;
}

.productwrapper {
    padding: 20px;
}

.product-code {
    padding-left: 0;
    padding-right:0;
}

@media screen and (max-width: 991px) {
    .legal-info {
        width: 100%;
    }

    .bottom-footer-row {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .bottom-footer-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}
/*Modal*/
.modal-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9000;
    display: none;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    background-color: rgba(0, 0, 0, .8);
    /*opacity: 0;*/
    color: #fff;
    font-weight: 300;
}

.close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 8px;
    font-family: 'Corin design', sans-serif;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
}

.modal-body {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    display: block;
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.section-title-block {
    margin-bottom: 50px;
    padding-right: 150px;
    padding-left: 150px;
    clear: both;
    text-align: center;
}

.modal-element-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
}

.modal-form-wrapper {
    display: block;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    float: none;
}

.modal-form {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.modal-background .close-btn {
    top: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 8px;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    z-index: 10;
    text-decoration: none;
}

@media (max-width: 767px) {
    .modal-body {
        position: relative;
        top: 0%;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .modal-element-wrapper {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}

.section.tint.bespoke {
    padding-bottom: 50px;
    font-size: 19px;
    line-height: 27px;
}

.full-width-container {
    padding-right: 50px;
    padding-left: 50px;
}

.full-width-inner-container, .title-wrapper-copy {
    padding-right: 10px;
    padding-left: 10px;
}

.w-richtext ul {
    padding: 0px;
}

.w-richtext li {
    font-weight: 300;
}

.text-align-center .button p {
    margin-bottom: 0px !important;
}
.text-align-center .button a:hover {
color:white;
}

.title-wrapper-copy {
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    font-size: 19px;
    line-height: 27px;
}

.bespoke-img {
    margin-bottom: 40px;
      width: 100%;
}
/*.bespoke-img a{
      width: 100%;
}*/
.bespoke-img .mbss-image-scale {
    width: 100%;
}
#pf-product-details-page .pf-product-image-thumb {
    width:100%;
}
.pf-product-main-image-wrapper .slick-slide img {
    margin:auto;
}
.pf-product-image-thumb-wrapper .slick-slide {
    margin: 0 2px;
}
.pf-product-main-image-wrapper .slick-track {
    display: flex !important;
}
.pf-product-main-image-wrapper .slick-track .slick-slide {
    display: flex!important;
    height: auto;
    align-items: center;
    justify-content: center;
}
.pf-slider-button {
    position: absolute;
    font-size: 25px;
    color: #858585;
    cursor: pointer;
    z-index: 9;
    opacity: 0.7;
    background-color: white;
    height: 100%;
    top: 0;
}
.pf-slider-button svg {
    position: relative;
    top: 35px;
}
.pf-slider-button.pf-slider-button-prev {
    left: 0;
}
.pf-slider-button.pf-slider-button-prev:hover, .pf-slider-button.pf-slider-button-next:hover {
    opacity: 1;
}
.pf-slider-button.pf-slider-button-next {
    right: 0;
}
.pf-voucher-input {
    top: -12px;
    position: relative;
    height:38px;
}
@media (max-width: 479px) {
    .full-width-container {
        padding-right: 10px;
        padding-left: 10px;
    }
    .pf-addresses-wrapper .w-col-6 {
    width:100%;
    }
}
.enquire-btn.contact.w-button {
    background-color:white;
}
.enquire-btn.contact.w-button:hover {
    background-color:#666;
}
.about-content-wrapper .blogpost-caption ul.redactor-toolbar {
position:relative!important;
}
.legal-info .mbss-text ul.redactor-toolbar {
    left: -20px !important;
}
.grid .title-wrapper {
margin-bottom:15px;
margin-top:60px;
}
.m-filter-wrapper{
    opacity:unset;
    display:block;
}
.pf-product-images-slider-arrow {
    background-color: transparent;
    opacity: unset;
}
@media (max-width: 767px) {
    .breadcrumb{
        padding-left: 0px;
    }
    #pf-product-list-page .product-list-header-text.p, #pf-product-list-page .breadcrumb {
        padding-left: 10px;
    }
    .pf-tab-header {
    width:100%!important;
    text-align:center;
    }
    .center-flex._3, .center-flex._4 {
    top:unset;
    }
}
.banner {
height:500px;
}
.slider-container {
    position:absolute!important;
}
.slider-container ul.mbss-textArea-btns {
left:20%!important;
top:-40px!important;
}
.m-filter-wrapper {
opacity:1;
}
@media (max-width: 992px) {
    .mapiframe iframe {
    width:100%;
    }
}
.contactv2 {
height:725px;
background-position:50% 33%;
}
@media (max-width: 992px) {
    .contactv2 {
        height: 330px;
    }
}
.about-content-wrapper img {
height:100%;
}
.filter-cats-sub .subdropdown-toggle.pf-filter-item {
padding:5px 10px;
}
.filter-subdropdown-link.pf-filter-item {
    padding: 5px 10px;
}
@media (max-width: 547px) {
    #product-sort {
        margin-left:0.5px;
    }
}
#cookie-bar {
z-index:999;
}
.contactv3 {
height:475px;
background-position:50% 50%;
}
.broadloom-banner.contactv3 {
    height: 300px;
}
.contactv3 .banner-container h3, .contactv3 .banner-container h2, .contactv3 .banner-container h1, .contactv3 .banner-container h4 {
    text-transform: none !important;
}
.grid-text .redactor-placeholder:after {
color:white;
}
.telephone .mbss-textArea-btns {
    top: 44px;
    left: -79px;
}
.new-right-top {
    top: 9px;
    right: 19px;
    width: 55px;
    height: 55px;
    font-size: 18px;
    line-height: 55px;
    z-index:1;
}
.new-left-top {
    position: absolute;
    top: 9px;
    right: 19px;
    width: 55px;
    height: 55px;
    border: 2px #fff;
    border-radius: 100%;
    background-color: #333;
    opacity: 1;
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    color: #fff;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    line-height: 55px;
    z-index:1;
}
.new-left-bottom {
    position: absolute;
    top: 9px;
    right: 19px;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #c51731;
    /*-webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);*/
    color: #fff;
    font-weight: 400;
    text-shadow: none;
    font-size: 10px;
    line-height: 55px;
    z-index:1;
}
.pf-new-product-tag, .pf-sale-product-tag {
background-color:#000;
}
.new-right-bottom {
    position: absolute;
    top: 9px;
    right: 19px;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    background-color: #eb9a0e;
    /*-webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);*/
    color: #fff;
    font-weight: 400;
    text-shadow: none;
    font-size: 12px;
    line-height: 55px;
    z-index:1;
}
.limited {
    background-color: #5a8557;
    line-height: 55px;
    z-index: 1;
    font-weight: 400;
    font-size: 8px;
}
.discon {
        background-color: #c51731;
        line-height: 55px;
        z-index: 1;
        font-weight: 400;
            font-size: 12px;
}
}
/*.new-left-bottom {
    position: absolute;
    top: 320px!important;
    left: 8px;
    width: 100px;
    height: 30px;
    border: 2px #fff;
    border-radius: 5%;
    background-color: #666;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}
.new-right-bottom {
    position: absolute;
    top: 320px!important;
    right: 8px;
    width: 100px;
    height: 30px;
    border: 2px #fff;
    border-radius: 5%;
    background-color: #666;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}*/
/*Featured Products*/
/*@media (max-width: 1300px) {
    .new-left-bottom, .new-right-bottom {
    top:340px!important;
    }
}
@media (max-width: 1200px) {
    .new-left-bottom, .new-right-bottom {
        top: 285px !important;
        width:90px;
    }
}
@media (max-width: 990px) {
    .new-left-bottom, .new-right-bottom {
        top: 260px !important;
    }
}
@media (max-width: 765px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}
@media (max-width: 455px) {
    .new-left-bottom, .new-right-bottom {
        top:60% !important;
    }
}
@media (max-width: 430px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}
@media (max-width: 390px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}*/

/*Product List*/
/*@media (max-width: 1300px) {
    .pf-product-list-item .new-left-bottom,.pf-product-list-item .new-right-bottom {
    top:310px!important;
    }
}
@media (max-width: 1200px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 270px !important;
        width:90px;
    }
}
@media (max-width: 990px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 250px !important;
    }
}
@media (max-width: 915px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 230px !important;
    }
}
@media (max-width: 850px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 208px !important;
    }
}
@media (max-width: 765px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 70% !important;
    }
}
@media (max-width: 600px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 63% !important;
    }
}
@media (max-width: 479px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top:68% !important;
    }
}
@media (max-width: 292px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 62% !important;
    }
}*/
/*Fix on hover transition for products*/
.productwrapper:hover {
    box-shadow: 0 6px 20px 4px rgba(26,26,26,.25);
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #666;
    text-decoration: none;
}
.div-block-7 h1, .div-block-7 h2, .div-block-7 h3 {
    margin-bottom: 0;
    padding: 10px 26px;
    border: 1px solid #666;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: 400;
}

.step-title-container h2, .step-title-container h3 {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 2px none #e0e0e0;
    color: #fff;
    font-size: 26px;
    line-height: 30px;
    margin-top: 10px;
    font-weight: 400;
}
.pt-b20.gal img {
    height: 100%;
}
.bespoke-image-main {
width:100%;
}
.bespoketab img {
    height: 600px;
    width:100%;
}
@media (max-width: 767px) {
    .pt-b20.gal .image-drop, .pt-b20.gal img {
        width: 100%;
    }
    .bespoke-image-main {
        min-height: 480px;
        margin-bottom: 20px;
    }
    .bespoketab img {
        height: 480px;
    }
    .div-block-7 {
bottom:15px;
    }
        #edit-details-btn, #save-address-btn {
    margin-left:10px;
    }
}
@media (max-width: 770px) {
    .brandsection, .cta-bg {
        background-attachment: scroll;
    }
    .full-width-inner-container.pt-b20.gal .img-overlay, .bespokeItem img {
        margin-bottom: 20px;
    }
    .full-width-inner-container.pt-b20.gal .separator {
    display:none;
    }
    .full-width-inner-container.pt-b20 .w-col-medium-3 p {
    margin-bottom:0px;
    }
}
@media (max-width: 430px) {
    .pt-b20.gal .image-drop {
        height: auto;
    }
    .bespoke-image-main {
        min-height: 300px;
    }
    .bespoketab img {
        height: 330px;
    }
    .div-block-7 {
        width: auto;
    }
    .contactv3 .spectralsc h1 {
    font-size:25px;
    line-height:33px;
    }
    }
.bespoketab {
position:relative;
}

    .div-block-7 {
        z-index: 99;
    }
.banner-container-right {
    position: absolute;
    right: 32px;
    bottom: 0;
    z-index: 10;
    padding: .5% .5% 0;
    background-color: rgba(0,0,0,.3);
    transform: translate(0,-50%);
    color: #fff;
    font-size: 19px;
    line-height: 27px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.66);
    min-width:360px;
}
.heading-5.spectralsc h1{
    font-family: 'Spectral SC',sans-serif;
}
.heading-5 h1{
    font-weight: 200;
    text-transform: none;
    text-shadow: 2px 2px 6px rgba(0,0,0,.66);
}
.justify p {
    text-align: justify;
    text-align-last: center;
    -moz-text-align-last: center;
}
.text-block-14, .text-span-2 {

display:inline-block;
}
.text-block-14 {
margin-bottom:0px;
}
@media (max-width: 457px) {
    .banner-container-right {
        right: 0px;
    }
}

.blog-details .blog-img {
margin-left:auto;
margin-right:auto;
display:block;
}
.pf-new-product-tag, .pf-sale-product-tag, .new-right-bottom, .new-left-bottom {
transform:rotate(15deg);
}
.new-left-bottom {
font-size:12px;
}
.lg-backdrop.in {
opacity:0.7!important;
}
.broadloomGallery .lg-toolbar.group {
display:none;
}
.broadloomGallery .lg-sub-html {
padding:34px 40px;
font-size:20px;
}
@media (max-width: 560px) {
.broadloomGallery .lg-toolbar.group {
display:block;
}
    .justify p {
    text-align:center;
    }
    .contactv3 .banner-container-right, .contactv2 .banner-container-right{
    width:100%;
    right:0;
    min-width:0px;
    }
}

#ui-id-1 {
background-color:#ddd;
background:#ddd;
}
.grid-item .list-item2-drop {
position:relative;
}
.grid-item .grid-text {
    z-index:auto;
}
.grid-item .galleryLink {
position:absolute;
width:100%;
height:100%;
top:0;
left:0;
}
.grid-item .grid-text-center {
z-index:999;
}
.grid-text-center a {
color:#fff;
text-decoration:underline;
}
.grid-text {
background-color:rgba(0,0,0,.6);
}
.pf-recent-related-product-item .product-image {
min-width:unset;
}
input::-ms-clear{
   display:none;
}
.catalogue.project-column .catalogueText 
{
    font-size:16px;
    text-align:center;
}
.catalogue.project-column .catalogueText p
{
    margin-bottom:0px;
}
.catalogue.project-column img {
border:1px solid black;
}
@media (max-width: 1024px) {
    .grid-item .grid-text {
    top:unset;
    margin-top:0px;
    min-height:61px;
    }
    .grid-item .grid-text-center {
        min-height:50px;
    }
    .grid-text-center p {
    margin-bottom:5px;
    }
    .broadloomGallery .lg-toolbar.group {
        display:block;
    }
}
@media (max-width: 425px) {
    #pf-product-listing .w-col-tiny-6 {
        width: 100%;
    }

}
#pf-product-listing .pf-product-list-options {
display:none;
}
@media (min-width: 770px) {
    #pf-product-listing .results-filter-wrapper.pf-product-list-options {
        display: block;
    }
}
.pf-product-main-image-wrapper {
text-align:center;
}
.catalogue.project-column .move-btn-list .glyphicon-move{
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: #337ab7
}
.catalogue.project-column .move-btn-list  .glyphicon-move:before {
    content: "\e068";
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.eot');
  src: url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
       url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.woff2') format('woff2'),
       url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.woff') format('woff'),
       url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
       url('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
.ui-menu-item {
    color:black;
}
.catalogueImg {
    text-align:center;
}
.flex-parent .flex-child .center-flex {
    padding:8px 30px 8px;
}
.flex-parent .flex-child  .center-flex p {
    margin-bottom:0px;
    font-size:16px
}
.flex-parent .flex-child  .center-flex h2 {
    font-size:30px
}
.flex-child a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
margin-top:5px;
margin-bottom:5px;
padding:8px 27px;
}
.roundedsection-content-wrapper .img-rounded {
border-radius:100%;
}
.roundedsection-content-wrapper .rounded-text p{
margin-bottom:0px;
text-align:left;
}
.roundedsection-content-wrapper .rounded-button {
text-align:center;
}
.roundedsection-content-wrapper {
text-align:center;
}
.section.rounded-section {
margin-bottom:0px;
}
.rounded-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    color:hsla(0,0%,40%,.6);
}


    .rounded-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        color:#666;
    }
    .rounded-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button)::after {
        content: '  →';
        position: relative;
        right: 0;
        display: block;
        width: 7px;
        height: 7px;
        display:inline-block;
        color:hsla(0,0%,40%,.6);
        text-decoration:none;
        font-size:24px;
            vertical-align: baseline;
            bottom: -2px;
    }

    .rounded-button a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover:after {
     color:#666;
    }
.w-section.content {
padding-top:25px;
}
#pf-my-account-page {
padding-top:0px;
}
.video-btn {
    left: 0;
    right: 0;
    display: inline-block;
    width: 44px;
    height: 44px;
    margin: 23% auto;
    font-size: 19px;
    line-height: 45px;
    position: absolute;
    text-align: center;
}

#pf-gallery-list-page .fixed-page-text-zone {
    text-align: center;
}
.gallery-img-wrapper {
margin-left:auto;
margin-right:auto;
margin-bottom:10px;
}
.gallery-video-wrapper {
position:relative;
display:block;
min-height:220px;
}
.gallery-video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.pf-gallery-item.w-col-6 {
padding:30px;
}
.pf-gallery-item .productwrapper {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}
.gallery-video-text {
text-align:center;
}
#gallery-image-holder.w-row {
margin-bottom:20px;
}
.pf-gallery-item {
margin-bottom:15px;
}
div#pf-product-actions-wrapper .pf-variant-grid-out-of-stock a.pf-btn.pf-btn-sm.pf-btn-primary.enquire, div#pf-product-actions-wrapper .variantAddToBasketWrapper .variant-table .pf-variant-grid-out-of-stock span {
    text-transform: none;
}
div#pf-product-actions-wrapper .variantGrid.pf-product-variant-item .pf-variant-grid-out-of-stock {
    text-transform: capitalize;
}
#pf-product-actions-wrapper .pf-product-variant-item .variant-enquire-wrapper, #pf-product-actions-wrapper .pf-product-variant-item .variantStockText {
    float: right;
}
#pf-product-actions-wrapper .pf-product-details-variant-table .description.p {
    line-height: 34px;
}
div#pf-product-actions-wrapper div#selectedVariantStockCount a#enquireAboutAvailability, div#pf-product-actions-wrapper .variant-table .pf-variant-grid-out-of-stock .variant-enquire {
    float: left;
}
div#pf-product-actions-wrapper div#selectedVariantStockCount{
    display: inline-block;
}
div#pf-product-actions-wrapper div#selectedVariantStockCount + p {
    margin: 0;
    padding: 0;
}
div#ModalProductVariant .popUpForAdmin, div#ModalProduct .popUpForAdmin {
    top: 400px;
}
div#ModalProduct .popUpForAdmin .title-wrapper{
    margin-bottom: 0;
}
@media (max-width: 767px) {
    #pf-gallery-list-page .w-col.w-col-3 {
    margin-top:20px;
    }
}
/*Changing color of gallery lightbox*/
.lg-inner {
    background-color: #fff;
}
.lg-actions .lg-next, .lg-actions .lg-prev,  .lg-sub-html, .lg-toolbar {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1080;
    color: #4a4747;
}
    .lg-toolbar .lg-icon,  #lg-counter {
        color: #4a4747;
    }
.lg-outer .lg-thumb-outer, .lg-outer .lg-toogle-thumb {
    background-color: rgba(0, 0, 0, 0.3)!important;
    color:#4a4747;
}
.lg-toolbar .lg-icon, .lg-outer .lg-toogle-thumb, .lg-prev.lg-icon, .lg-next.lg-icon {
    color: #fff!important;
}
.product-list-header-img.pf-product-list-header-img {
margin-bottom:22px;
}

.productwrapper:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

#variant-select {
    max-width: 100%;
}

#pf-product-details-page .pf-product-details-variant-grid-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
}
.nav-link {
    font-size:16px;
    color:#333;
}
.mid-footer, .dropdown-link, body, .bottomfooter {
    font-size: 16px;
}
.grid-text-center.mbss-editmode {
    top:-45px;
}
.top-right-first .nav-link.mid {
    padding-top: 18px;
    padding-bottom: 5px;
}

.top-right-second .nav-link.mid {
    padding-top: 5px;
    padding-bottom: 18px;
}
.top-right .top-right-first, .top-right .top-right-second {
    text-align: right;
}
.pf-btn.pf-btn-xs {
    padding: 6px;
}


@media screen and (max-width: 1199px) {
    #pf-product-actions-wrapper .pf-product-variant-item .variant-enquire-wrapper, #pf-product-actions-wrapper .pf-product-variant-item .variantStockText {
        float: none;
    }
}

@media screen and (max-width: 991px) {
    #pf-product-actions-wrapper .pf-product-variant-item .variant-enquire-wrapper, #pf-product-actions-wrapper .pf-product-variant-item .variantStockText {
        float: right;
    }
    #pf-product-details-page .pf-product-details-variant-grid-wrapper {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 767px) {
    #pf-product-details-page .pf-product-details-variant-grid-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .top-right-first .nav-link.mid {
        padding-top: 13px;
    }

    .top-right-second .nav-link.mid {
        padding-bottom: 13px;
    }
}
@media (max-width: 767px) {
    .nav-menu.w-nav-menu.w--nav-menu-open .nav-link {
        color: #fff;
    }
}
@media screen and (max-width: 599px) {
    #pf-product-actions-wrapper .pf-product-variant-item .variant-enquire-wrapper, #pf-product-actions-wrapper .pf-product-variant-item .variantStockText {
        float: none;
    }
}

@media screen and (max-width: 479px) {
    #pf-product-details-page .pf-product-details-variant-grid-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}