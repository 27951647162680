/* Customisation Classes */
.pf-btn.pf-btn-colour-one{
    background-color:#444;
    color:#fff;
}
.pf-btn.pf-btn-colour-one:hover{
    background-color:deeppink;
}
.pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-one:focus{
    border-color:deeppink;
}
.pf-link-colour-one{
    color:#333;
}
.pf-link-colour-one:hover{
    color:#333;
    text-decoration:underline;
}


.pf-btn.pf-btn-colour-two{
    background-color:#444;
    color:#fff;
}
.pf-btn.pf-btn-colour-two:hover{
    background-color:deeppink;
}
.pf-input.pf-hover-colour-two:hover, .pf-input.pf-hover-colour-two:focus{
    border-color:deeppink;
}
.pf-link-colour-two{
    color:#333;
}
.pf-link-colour-two:hover{
    color:#333;
    text-decoration:underline;
}

.pf-form-warn-msg{
    padding: 3px 0px 0px 0px;
    color: #dc143c;
    font-size: 12px;
}


/* general classes */
.pf-pull-right{
    float:right;
}
.pf-pull-left {
    float: left;
}
a.pf-normal-link{
    color:#333;
    text-decoration:underline;
}
    a.pf-normal-link:hover {
        color: #000;
    }
a.pf-icon-link {
    color: #333;
    text-decoration: none;
}

.pf-page-wrapper{
    margin-top:20px;
    margin-bottom:40px;
}
.hidden, .pf-hidden, .pf-hide{
    display:none;
}
.pf-input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
    vertical-align:top;
}
    .pf-input:focus {
        border-color: #1d89ff;
        outline: 0;
    }

.pf-input-with-btn-wrapper{
    position:relative;
}
    .pf-input-with-btn-wrapper .pf-btn.pf-tbx-btn{
        position: absolute;
        top: 0px;
        right: 0px;
        width: 55px;
        height: 34px;
        border-radius:0 5px 5px 0;
    }
    .pf-input-with-btn-wrapper .pf-input {
        border-radius: 0 5px 5px 0;
    }

.pf-btn.pf-btn-with-icon{
    font-size: 18px;
    padding: 7px;
    text-align: center;
}


.pf-alert-wrapper {
    display: none;
    color: #efc53a;
}
.pf-alert-wrapper.pf-show{
    display:block;
}
.pf-alert-block{
    padding:10px;
}
.pf-table{
    width:100%;
}
.pf-table th{
    text-align:left;
}
.pf-table thead tr{
    border-bottom:1px solid #ccc;
}
.pf-table td, .pf-table th {
    padding:5px 0;
}
.pf-hide-mobile{
    display:none;
}
.pf-show-mobile{
    display:block;
}
tr.pf-hide-mobile {
    display: none;
}
tr.pf-show-mobile {
    display: table-row;
}
.pf-empty-table{
    text-align:center;
    padding:20px 0;
}

.pf-block{
    margin-bottom:15px;
}

.pf-green {
    color: #218838;
}

.pf-text-center, th.pf-text-center{
    text-align:center;
}

/* Flexbox classes */
.pf-flex-container{
    display:flex;
}

/****** Forms *******/
.pf-form-row-input-with-btn .pf-input {
    display: inline-block;
    width: 70%;
}
.pf-form-row-input-with-btn .pf-btn {
    display: inline-block;
    width: 15%;
    height: 38px;
    margin-bottom: 10px;
    vertical-align: middle;
}

/****** Quick Order ******/
.pf-show-favs-list{
    text-align:center;
    padding-top:5px;
}
.pf-quick-order-wrapper{
    margin-bottom:40px;
}
.pf-quick-order-top{
    background-color:#f1f1f1;
    padding:15px;
    margin-top:20px;
    margin-bottom:20px;
}
.pf-quick-order-top .pf-input{
    margin-bottom:0;
    display:inline-block;
}
.pf-quick-order-wrapper .pf-table{
    background-color:#fff;
}
.pf-quick-order-wrapper .pf-table th{
    font-weight:500;
    background-color:#444;
    color:#fff;
}
.pf-quick-order-wrapper .pf-table tr{
    border:1px solid #444;
    color:#333;
}
.pf-quick-order-wrapper .pf-table-title{
    padding: 5px 0px 15px 5px;
}
.pf-quick-order-wrapper hr{
    color: #f1f1f1;
    margin-top:15px;
}
.pf-quick-order-cart-header{
    text-align:center;
    font-size:20px;
    font-weight:400;
}
.pf-quick-order-cart-footer{
    padding:20px 0;
    text-align:right;
}
.pf-quick-order-results-wrapper{
    display:none;
}
.pf-quick-order-quick-entry > input{
    width:50%;
}
.pf-table-close-btn{
    float:right;
}

/****** product list ******/
#pf-product-listing{
    margin-bottom:40px;
}
.pf-sub-cats-listing {
    margin-top: 20px;
}
.pf-product-listing {
    margin-top: 15px;
}
.pf-product-list-options label {
    float: left;
    font-weight: 400;
    margin-top:10px;
}
.pf-product-list-options select {
    float: left;
    width: auto;
    height: 38px;
    margin: 0 7px 0;
}

.pf-new-product-tag {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 55px;
    height: 55px;
    border: 2px #fff;
    border-radius: 100%;
    background-color: #666;
    opacity: 1;
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    color: #fff;
    font-size: 15px;
    line-height: 57px;
    font-weight: 400;
    text-align: center;
}

.pf-sale-product-tag {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 55px;
    height: 55px;
    border: 2px #fff;
    border-radius: 100%;
    background-color: #666;
    opacity: 1;
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    color: #fff;
    font-size: 15px;
    line-height: 57px;
    font-weight: 400;
    text-align: center;
}
#pf-product-listing .w-col, #pf-related-recent-products-wrapper .w-col {
    padding-left: 5px;
    padding-right: 5px;
}
.pf-related-recent-tab-content{
    padding: 10px 10px 30px 10px;
}
#pf-related-recent-products-wrapper .pf-tab-menu {
    border-bottom: 1px solid #ccc;
}
.pf-product-list-item, .pf-recent-related-product-item {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-top: 5px;
    background-color: #fff;
    -webkit-transition: color .5s ease,opacity .5s ease;
    transition: color .5s ease,opacity .5s ease;
    color: #666;
    font-size: 14px;
    color: #666;
    text-align: center;
    box-shadow: 0 8px 20px 0 rgba(78,78,78,.1);
    -webkit-transition: color .5s ease,opacity .5s ease,box-shadow .9s ease,-webkit-transform .6s ease;
    transition: color .5s ease,opacity .5s ease,box-shadow .9s ease,-webkit-transform .6s ease;
    transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease;
    transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease,-webkit-transform .6s ease;
}
    .pf-product-list-item:hover, .pf-recent-related-product-item:hover {
        box-shadow: 0 6px 20px 4px rgba(26,26,26,.25);
        /*-webkit-transform: translate(0,-5px);
        -ms-transform: translate(0,-5px);
        transform: translate(0,-5px);*/
    }

.pf-product-name, .pf-recent-related-product-item a {
    font-size: 18px;
    line-height: 26px;
    color: #666;
    text-decoration: none;
    position:relative;
}

.pf-product-list-item a{
    text-decoration: none;
}

.pf-product-list-item .pf-product-code, .pf-product-list-item .pf-stock-info {
    color: #999;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
}
.pf-additional-list-info{
    color: black;
    font-size: 15px;
    margin-bottom:10px;
    
}

.pf-custom-stock-message {
    color: #41bd3c;
}
.pf-product-list-item .pf-custom-stock-message, #pf-product-details-page .pf-custom-stock-message {
    margin-bottom: 10px;
}

.pf-product-list-item .pf-product-price {
    color: #666;
    font-size:14px;
}
.pf-product-sale-price {
    text-decoration:line-through;
}

.pf-product-list-item .pf-add-cart-wrapper {
    margin-top:15px;
}

.pf-btn.pf-add-to-cart-btn {
    border-radius:0 4px 4px 0;
    height:34px;
}

.pf-qty-select {
    height: 34px;
    border: 1px solid #666;
    text-align:center;
    width:35px;
}

.pf-qty-wrapper {
    display: inline-block;
    white-space: nowrap;
}
/*.list-view-add-cart-wrapper .has-variants{
    margin-top:2px;
}*/
.pf-qty-btn {
    display: inline-block;
    font-size: 8px;
    height: 34px;
    width: 22px;
    border-top: 1px solid #666;
    position: relative;
    top: 0;
    border-bottom: 1px solid #666;
    padding: 0;
    text-align: center;
    vertical-align: top;
    background-image: -webkit-linear-gradient(white 0,#f3f3f3 100%);
    background-image: linear-gradient(white 0,#f3f3f3 100%);
}

    .pf-qty-btn.pf-qty-btn-minus {
        border-left: 1px solid #666;
        /*padding-bottom: 4px;*/
        /*top:0;*/
    }

    .pf-qty-btn.pf-qty-btn-plus {
        border-right: 1px solid #666;
    }

    .pf-qty-btn:active {
        background-color: #fff;
    }

.pf-product-list-item .pf-btn {
    display:inline-block;
    height:34px;
}

a.pf-stock-notify-me-btn {
    color: #0062cc;
    text-decoration:underline;
}
.pf-list-view-group-prods-wrapper{
    display:flex;
    justify-content:center;
}
.pf-list-view-group-prod{
    margin:5px;
    border:1px solid #fff;
}
.pf-list-view-prod-link{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
}
.pf-list-group-image-isdefault, .pf-list-group-image-selected{
    border:1px solid #333;
}
/****** product list filters ******/
.pf-filter-heading.mobile {
    display: none;
}
.pf-filter-wrapper {
    margin-bottom: 20px;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
    border: 1px solid #ddd;
    background-color: #eee;
    color: #666;
    font-weight: 400;
}
.pf-filter-heading {
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 10px;
    background-color: #fff;
    line-height: 20px;
    color: #666;
    font-size: 15px;
    font-weight: 700;
}

.pf-filter-item {
    display: inline-block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid hsla(0,0%,100%,.4);
    font-size: 15px;
    cursor: pointer;
    position: relative;
}
.pf-filter-dropdown-btn, .pf-spec-filter-dropdown-btn {
    -moz-transition: transform 500ms ease 0s;
    -o-transition: transform 500ms ease 0s;
    -webkit-transition: transform 500ms ease 0s;
    transition: transform 500ms ease 0s;
}

    .pf-filter-dropdown-btn.closed, .pf-spec-filter-dropdown-btn.closed {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

.pf-filter-wrapper .pf-filter-dropdown-btn, .pf-filter-wrapper .pf-spec-filter-dropdown-btn {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 20px auto auto;
    opacity: 0.5;
}

.pf-filter-header {
    cursor: pointer;
}
.pf-filter-header .pf-filter-main-dropdown-btn {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 6px;
    margin: 10px 20px auto auto;
    opacity: 0.5;
    -moz-transition: transform 500ms ease 0s;
    -o-transition: transform 500ms ease 0s;
    -webkit-transition: transform 500ms ease 0s;
    transition: transform 500ms ease 0s;
    transform: rotate(-90deg);
}
    .pf-filter-header .pf-filter-main-dropdown-btn.closed {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    .pf-filter-header .pf-filter-main-dropdown-btn.open {
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
.pf-filter-items
{
    display:none;
}
.pf-filter-dropdown-icon {
    display: inline-block;
}
.pf-filter-dropdown{
    display:block;
}

.pf-filter-item a{
    text-decoration:none;
    color:#666;
}

.pf-filter-item a:hover {
    text-decoration: underline;
    color: #666;
}

.pf-filter-item a.cat-selected{
    text-decoration:underline;
    font-weight:bold;
}

.pf-filter-dropdown-list{
    background-color:#fff;
}
.pf-filter-item input {
    float: left;
    top: 2px;
    position: relative;
    margin-right: 6px;
}
.pf-filter-item label{
    float: left;
    font-weight:normal;
    margin-bottom:0;
}
.pf-filter-item .filter-count {
    opacity:0.75;
}
#pf-clear-filters-btn {
    display: none;
    padding: 10px;
    text-align: center;
    width: 100%;
    color: #666;
    text-decoration:none;
}
#pf-clear-filters-btn:hover{
    text-decoration:underline;
}

.filter-subdropdown-list .filter-subdropdown-link {
    margin-bottom: 0;
}

.pf-paging-btn {
    display: inline-block;
    border: 1px solid #666;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;
    webkit-transition: color .5s ease, background-color .5s ease, border .6s ease;
    transition: color .5s ease, background-color .5s ease, border .6s ease;
    padding: 5px 7px;
    color:#666;
    text-decoration:none;
    margin-top:5px;
}
    .pf-paging-btn.pf-paging-current {
        color: #fff;
        background-color: #666;
    }
    .pf-paging-btn:hover {
        background-color: #666;
        color: #fff;
    }
/****** buttons ******/
.pf-btn-primary,
.pf-btn,
.pf-btn-lg,
.pf-btn-md,
.pf-btn-sm,
.pf-btn-xs {
    color: #1d89ff;
}

.pf-btn-default {
    color: #666;
}

.pf-btn-warning {
    color: #feab3a;
}

.pf-btn-danger {
    color: #ff5964;
}

.pf-btn-success {
    color: #28b78d;
}

.pf-btn-royal {
    color: #bd2df5;
}
.pf-btn {
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: transparent;
    background: transparent;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
    overflow: hidden;
    border-width: 0;
    border-radius: 4px;
    background: rgba(255,255,255,0.4);
    color: #fff;
    webkit-transition: color .5s ease, background-color .5s ease, border .6s ease;
    transition: color .5s ease, background-color .5s ease, border .6s ease;
    text-decoration: none;
    text-align: center;
    height:34px;
}

a.pf-btn {
    display: inline-block;
    overflow: visible;
}

    /*.pf-btn:hover,
    .pf-btn:focus {
        opacity: 0.75;
        text-decoration: none;
    }*/

    .pf-btn.pf-btn-xs {
        padding: 1px 6px;
        font-size: 12px;
        font-family: inherit;
    }

    .pf-btn.pf-btn-sm {
        padding: 5px 10px;
        font-size: 15px;
        font-family: inherit;
    }

    .pf-btn.pf-btn-md {
        font-size: 15px;
        font-family: inherit;
        /*padding: 9px 14px;*/
    }

    .pf-btn.pf-btn-lg {
        padding: 8px 15px;
        font-size: 24px;
        font-family: inherit;
    }
    .pf-btn.pf-btn-default {
        background: #fff;
        border: 1px solid #ccc;
        color:#666;
    }
    .pf-btn.pf-btn-sm.pf-btn-default {
        padding:5px 10px;
    }
    .pf-btn.pf-btn-md.pf-btn-default {
        padding: 8px 14px;
    }

    .pf-btn.pf-btn-with-qty {
        border-left: none;
        vertical-align:top;
        border-radius:0 5px 5px 0;
    }

    .pf-btn.pf-btn-primary,
    .pf-btn.pf-btn-primary:focus {
        /*background: #1d89ff;*/
        border: 1px solid #666;
        color: #666;
    }
        .pf-btn.pf-btn-primary:hover
        {
            background-color: #666;
            color: #fff;
            text-decoration: none;
        }
        .pf-btn.pf-btn-primary.pf-btn-with-qty {
            border-left: none;
        }

    .pf-btn.pf-btn-secondary,
    .pf-btn.pf-btn-secondary:focus{
        background: #007bff;
        border: 1px solid #007bff;
        color: #fff;
    }

        .pf-btn.pf-btn-secondary:hover {
            background-color: #0069d9;
            border-color:#0062cc;
            color: #fff;
            text-decoration: none;
        }
        .pf-btn.pf-btn-secondary.pf-btn-with-qty {
            border-left: none;
        }

    .pf-btn.pf-button-with-input {
        padding: 10px;
        top: -4px;
        left: -5px;
        border-radius: 0px 10px 10px 0px;
        display:inline;
    }
    .pf-btn.pf-btn-default.pf-button-with-input {
        padding: 9px 10px;
    }
    .pf-btn.pf-hidden{
        display:none;
    }


.pf-list-view-paging{
    float:right;
}

.pf-product-list-header-img{
    margin-bottom:10px;
}

/****** Top Cart ********/
#top-cart td, #top-cart {
    border: 1px solid #ccc;
}
    #top-cart th, #top-cart td{
        padding: 3px;
    }
#top-cart-holder  {
    color:#666;
}
    #top-cart-holder #bag-closer:hover {
        text-decoration:none;
    }

    #top-cart-holder .pf-icon-window-close {
        font-size: 17px;
        color: #666;
        top: 1px;
        position: relative;
    }


    .pf-mobile-qty-wrapper {
        margin-top:3px;
    }

/****** Product Details ******/
#pf-product-details-page .pf-brand-info{
    text-align:center;
    margin-top:10px;
}
.pf-product-images-wrapper-static{
    display:none;
}
#pf-product-details-page .pf-product-images-wrapper-slider {
    margin-bottom: 20px;
    height: 100%;
}
.pf-product-code{
    margin-bottom:10px;
}
.pf-product-details-heading {
    margin-top:10px;
    margin-bottom:10px;
}
.pf-product-details-sub-heading {
    font-size: 20px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 5px;
}
.pf-spec-table table,
.pf-disc-table table {
    border:1px solid #ccc;
    width:100%;
    margin-bottom:20px;
}
.pf-spec-table th,
.pf-spec-table td,
.pf-disc-table th,
.pf-disc-table td {
    padding:5px;
    border:1px solid #ccc;
}
.pf-spec-table tbody tr:nth-child(odd),
.pf-disc-table tbody tr:nth-child(odd) {
    background-color: #eee;
}
.pf-product-files{
    margin-bottom:20px;
}
.pf-detail-view-add-cart-wrapper .pf-btn {
    display: inline-block;
}
.pf-parts-diagram-link{
    margin-bottom:20px;
}
.pf-product-options-wrapper {
    margin-bottom: 20px;
}
.pf-product-details-price-wrapper{
    margin-bottom:20px;
    font-size:24px;
    margin-top:5px;
}
.pf-product-details-price-wrapper .pf-disc-table {
    margin-top: 15px;
    font-size: 14px;
}
.pf-product-details-price-wrapper .pf-disc-table td {
    padding: 1px 5px;
}
.pf-platform-modal-text .pf-product-details-price-wrapper .pf-disc-table {
    margin-left: auto;
    margin-right: auto;
}
.pf-price-old{
    text-decoration:line-through; 
    opacity:0.75;
}

.pf-details-social-icons-wrapper{
    margin-bottom:20px;
    min-height:70px;
}
.pf-page-wrapper .pf-tabs-top {
    border-bottom: 1px solid #ccc;
}
#pf-related-recent-products-wrapper 
{
    margin-top:40px;
}
.pf-tabs-wrapper {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
}
.pf-page-wrapper .pf-tab-header.w--current {
    background-color: #fff;
    position: relative;
    top: 1px;
    border: 1px solid #ccc;
    border-bottom: none;
}
.pf-page-wrapper .pf-tab-header {
    width: 100%;
    border-radius: 10px 10px 0 0;
    top: 1px;
    background-color: #f1f1f1;
    width: auto;
    display: inline-block;
    padding: 10px 5px;
    font-size: 12px;
}
.pf-page-wrapper .pf-tab-header:hover {
    text-decoration: none;
}
.pf-page-wrapper .pf-tab-header-text {
    text-align: center;
}

#pf-product-details-page .pf-slider-image-holder {
    text-align: center;
}
#pf-product-details-page .pf-product-images-wrapper-slider {
    background-color: unset;
}
.pf-recent-related-slider-counter{
    display:none;
}
.pf-tabs-top{
    margin-bottom:20px;
}
.pf-tab-product-name{
    font-size:18px;
    margin-top:10px;
    text-align:center;
}
.pf-slider-image-holder a:hover {
    text-decoration: none;
}
.pf-product-images-slider-arrow {
    background-color: #000;
    opacity: 0.05;
}
#pf-product-details-page .pf-product-image-thumb {
    float: left;
    width: 32%;
    margin-right: 1%;
    margin-left: 1%;
    margin-bottom: 20px;
    padding: 0;
}
    #pf-product-details-page .pf-product-image-thumb:first-child {
        margin-left: 0;
    }
    #pf-product-details-page .pf-product-image-thumb:nth-child(4n) {
        margin-left: 0;
    }
    #pf-product-details-page .pf-product-image-thumb:nth-child(3n) {
        margin-right: 0;
    }
    #pf-product-details-page .pf-product-image-thumb.w--current{
        border:1px solid #666;
    }
.pf-product-main-image-wrapper{
    margin-bottom:20px;
}

#pf-product-actions-wrapper 
{
    padding: 30px 20px 30px 20px;
    border-radius: 20px;
    margin-bottom: 40px;
    border: 1px solid #ccc;
}
.pf-product-details-variant-table .pf-product-details-price-wrapper {
    margin-bottom: 14px;
    font-size: 21px;
    margin-top: 2px;
    line-height: 28px;
}
.pf-product-details-description{
    margin-bottom:20px;
}
.pf-product-details-info-wrapper{
    margin-bottom:20px;
}
#pf-product-details-page .pf-detail-view-add-cart-wrapper{
    margin-bottom:20px;
}
#pf-product-details-page .pf-product-details-variant-table .pf-detail-view-add-cart-wrapper {
    margin-bottom: 10px;
}
#pf-product-details-page .pf-product-details-variant-table:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
#pf-product-details-page .pf-product-details-variant-grid-wrapper {
display:grid;
grid-gap: 10px;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
#pf-product-details-page .pf-product-details-variant-grid {
    padding: 10px;
    text-align: center;
    border: 1px solid #eee;
    cursor:pointer;
    position:relative;
    display:flex;
    border-radius:5px;
    box-shadow: 0 8px 20px 0 rgba(78,78,78,.1);
    -webkit-transition: color .5s ease,opacity .5s ease,box-shadow .9s ease,-webkit-transform .6s ease;
    transition: color .5s ease,opacity .5s ease,box-shadow .9s ease,-webkit-transform .6s ease;
    transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease;
    transition: color .5s ease,opacity .5s ease,transform .6s ease,box-shadow .9s ease,-webkit-transform .6s ease;
}
#pf-product-details-page .pf-product-details-variant-grid:hover {
box-shadow: 0 6px 20px 4px rgba(26,26,26,.25);
}
#pf-product-details-page .pf-product-details-variant-grid .cancel{
    position: absolute;
    top:50%;
    left:50%;
    width:auto;
    height:auto;
    opacity:0.4;
  font-size:4em;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  font-weight: 100;
}
#pf-product-details-page .pf-variant-grid-out-of-stock {
text-transform:uppercase;
}
#pf-product-details-page .pf-variant-grid-disable {
opacity: 0.6;
}
#pf-product-details-page .pf-product-details-variant-grid-selected {
    border: 1px solid #000;
}
#pf-product-details-page .variantGrid {
display:none;
margin-top:20px;
}
#pf-product-details-page .pf-product-variant-grid-title {
margin:auto;
}


.pf-product-group-wrapper{
    display:flex;
    margin-bottom:30px;
}
.pf-group-prod{
    margin-right: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    position:relative;
    opacity:0.8;
    text-align:center;
}
.pf-group-prod.pf-group-item-selected{
    border-color:#999;
    opacity:1;
}
.pf-group-prod-img{
    margin-bottom:5px;

}
.pf-list-view-group-prods-wrapper {
    display: flex;
    justify-content: center;
}
.pf-prod-group-title{

}
.pf-group-item-link{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
}
.pf-product-component-select-wrapper{
    margin-bottom:30px;
    border:1px solid #ccc;
}
.pf-product-component-accordion {
  border:1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border-bottom: 1px solid #eee;
}
.pf-product-component-accordion:nth-last-child(2){
  border-bottom:none;
}

.pf-product-component-accordion-active, .pf-product-component-accordion:hover {
  background-color: #eee;
}

.pf-product-component-accordion-panel {
  padding: 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.pf-product-component-accordion-panel{
    overflow-y: auto;
}
.pf-product-component-accordion-panel-inner{
    padding:10px;
    display:grid;
    grid-gap:10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-height: 350px;
}
.pf-product-component-select{
    padding: 10px;
    text-align: center;
    border: 1px solid #eee;
}
.pf-product-component-select-title {
font-size:14px;
}
.pf-product-component-select-stock {
font-size:12px;
}
@media (max-width: 472px) {
    .pf-product-component-accordion-panel-inner {
        padding: 10px;
        grid-template-columns: 1fr 1fr;
    }
    .pf-quick-order-results-wrapper {
    overflow-x:auto;
    }
    .pf-platform-modal-text {
    font-size:13px;
    }

    #pfQoSearchResults {
    font-size:13px;
    }
    #pfQoSearchResults .pf-qo-select-options, .pf-platform-modal-text .pf-btn.pf-add-to-cart-btn {
    height:50px;
    }
}
.pf-product-component-select:hover{
    border:1px solid #ccc;
    cursor:pointer;
}
.pf-product-component-select.pf-product-component-selected{
    border: 1px solid #ccc;
}
.pf-component-select-text{
    text-decoration:none;
}
u.pf-dotted{
  border-bottom: 1px dashed #999;
  text-decoration: none; 
}
.pf-product-personalization {
margin-bottom:20px;
}
.pf-product-personalization-input {
width:50%;
}
.pf-product-component-included-wrapper{
    margin-bottom: 30px;
}
.pf-included-items-container{
    padding:10px;
    display:grid;
    grid-gap:10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-height: 350px;
}
.pf-option-included-item{
    padding: 10px;
    text-align: center;
    border: 1px solid #eee;
}

/********** Checkout pages *********/
#pf-checkout-signin {
    margin-bottom: 40px;
}
.pf-checkout-cart-table {
    margin-top: 20px;
    border: 1px solid #ccc;
}
.pf-checkout-cart-table th{
    padding:10px;
}
.pf-checkout-cart-table td{
    padding:3px;
    border:1px solid #ccc;
}
.pf-voucher-input {
    display: inline-block;
    width: 70%;
}

#pf-discount-input-wrapper {
    width: 35%;
    min-width: 300px;
}
.pf-checkout-signin-actions a{
    width:100%;
    margin-top:30px;
}
.pf-checkout-login-wrapper{
    margin-bottom:60px;
}
.pf-checkout-hr {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #f1f1f1; /* old IE */
    background-color: #f1f1f1; /* Modern Browsers */
    margin: 20px 0 0;
}
.pf-checkout-page{
    margin-bottom:50px;
}
.pf-checkout-actions{
    margin-top:30px;
    text-align:center;
}
.pf-delivery-options-wrapper ul{
    padding-left:20px;
}
.pf-delivery-options-wrapper {
    text-align:center;
}
.pf-delivery-options-wrapper h4 {
    text-align: center;
}
.pf-checkout-secure-image{
    margin-bottom:20px;
}
.pf-payment-result-page{
    margin-bottom:40px;
}
.pf-sagepay-image-wrapper{
    text-align:center;
}
.pf-checkout-cart-table .pf-checkout-cart-footer-row th{
    font-size:16px;
    padding:10px;
    font-weight:600;
}
/****** My Account *********/
.pf-order-details {
    background-color: #f1f1f1;
    padding: 3px;
}
.pf-fav-list-item {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #f1f1f1;
}
.pf-favs-table td{
    padding:10px 5px;
}
#pf-my-account-page .pf-tabs-wrapper{
    padding:0;
}
#pf-my-account-page .pf-my-acc-info-wrapper {
    padding: 10px;
}
#pf-my-account-page .pf-add-to-cart-btn {
    margin-bottom: 10px;
}
.pf-reset-pass{
    float:right;
}
.pf-terms-wrapper {
    background-color: #f1f1f1;
    padding: 15px 15px;
}
.pf-terms-wrapper h4{
    margin-top:0;
}
.pf-terms-wrapper a{
    color:#333;
}
.pf-terms-wrapper p{
    margin:0;
}

/******** Modals ********/
.pf-modal-w575{
    max-width:575px;
}


/****** size rules ******/
@media (min-width: 768px) {
    .pf-hide-mobile {
        display: block;
    }
    .pf-show-mobile {
        display: none;
    }
    tr.pf-hide-mobile {
        display: table-row;
    }
    tr.pf-show-mobile {
        display: none;
    }
    .pf-filter-header {
        display: none;
    }
    .pf-filter-heading{
        display:block;
    }
    #top-cart th, #top-cart td {
        padding: 5px;
    }
    #pf-product-details-page .pf-brand-info {
        margin-top: 0;
    }
    .pf-product-details-heading{
        margin-top:0;
    }
    
    .pf-product-images-wrapper-slider {
        display: none;
    }
    .pf-product-images-wrapper-static {
        display: block;
    }
    .pf-tabs-wrapper .pf-recent-related-product-item {
        padding-bottom: 15px;
    }

    .pf-page-wrapper .pf-tabs-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .pf-page-wrapper .pf-tab-header {
        padding: 10px 25px;
        font-size: 14px;
    }
    .pf-checkout-signin-actions a {
        width: auto;
    }
    .pf-checkout-actions {
        text-align: left;
    }
    .pf-delivery-options-wrapper {
        text-align: left;
    }

        .pf-delivery-options-wrapper h4 {
            text-align: left;
        }
    .pf-table td, .pf-table th {
        padding:5px;
    }
    .pf-filter-header .pf-filter-main-dropdown-btn {
        transform: rotate(0deg);
    }
    .pf-filter-items {
        display: block;
    }
}

@media (min-width: 992px) {
    .pf-mobile-qty-wrapper {
        display: none;
    }
}
.cart-first {
text-decoration:none;
}
@media (max-width: 992px) {

    .pf-btn.pf-btn-sm {
        font-size: 12px;
    }
    .pf-btn.pf-add-to-cart-btn {
        border-radius: 4px 4px 4px 4px;
        border-left: 1px solid #666!important;
    }
}
@media (min-width: 768px) {
    table.cart-table tbody > tr > td {
        padding: 4px!important;
    }
}
.hide-text {
display:none;
}
.new-left-top {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 55px;
    height: 55px;
    border: 2px #fff;
    border-radius: 100%;
    background-color: #333;
    opacity: 1;
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
    color: #fff;
    font-size: 15px;
    line-height: 57px;
    font-weight: 400;
    text-align: center;
}
.new-left-bottom {
    position: absolute;
    top: 340px!important;
    left: 8px;
    width: 100px;
    height: 30px;
    border: 2px #fff;
    border-radius: 5%;
    background-color: #333;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}
.new-right-bottom {
    position: absolute;
    top: 340px!important;
    right: 8px;
    width: 100px;
    height: 30px;
    border: 2px #fff;
    border-radius: 5%;
    background-color: #333;
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
}
.pf-loading-spinner {
    border: 8px solid #ffffff;
    border-radius: 50%;
    border-top: 8px solid #666666;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display:block;
  margin:0 auto;
}
/*Promotion Strip*/
.pf-promotion-container {
  display: flex;
  align-items: center;
  justify-content:center;
}
.pf-promotion-container p {
    margin-top:10px;
    margin-bottom:10px;
}
/*Cart Modal*/
#cartModal {
max-width:900px;
}
.pf-continue-shopping-cart {
margin-right:10px;
}
@media (max-width: 479px) {
#cartModal {
padding:35px 0px;
}
    .pf-proceed-checkout-cart {
    margin-right:10px;
    }
}
/*Featured Products*/
@media (max-width: 1300px) {
    .new-left-bottom, .new-right-bottom {
    top:340px!important;
    }
}
@media (max-width: 1200px) {
    .new-left-bottom, .new-right-bottom {
        top: 285px !important;
        width:90px;
    }
}
@media (max-width: 990px) {
    .new-left-bottom, .new-right-bottom {
        top: 260px !important;
    }
}
@media (max-width: 765px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}
@media (max-width: 455px) {
    .new-left-bottom, .new-right-bottom {
        top:60% !important;
    }
}
@media (max-width: 430px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}
@media (max-width: 390px) {
    .new-left-bottom, .new-right-bottom {
        top: 60% !important;
    }
}

/*Product List*/
@media (max-width: 1300px) {
    .pf-product-list-item .new-left-bottom,.pf-product-list-item .new-right-bottom {
    top:310px!important;
    }
}
@media (max-width: 1200px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 270px !important;
        width:90px;
    }
}
@media (max-width: 990px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 250px !important;
    }
}
@media (max-width: 915px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 230px !important;
    }
}
@media (max-width: 850px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 208px !important;
    }
}
@media (max-width: 765px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 70% !important;
    }
}
@media (max-width: 600px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 63% !important;
    }
}
@media (max-width: 479px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top:68% !important;
    }
}
@media (max-width: 292px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 62% !important;
    }
}

/* Gallery */
.pf-gallery-item > div {
    cursor: pointer;
}

.pf-gallery-cat-description table {
    width: 100%;
    margin-bottom: 20px;
}
.pf-gallery-cat-description table tbody tr:nth-child(odd) {
    background: #eee;
}
.pf-gallery-cat-description table tbody td {
    padding: 5px;
    border: 1px solid #ccc;
}